import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addFaq } from "../../redux/slices/dashboard";
import { toast } from "react-toastify";

function CreateFaq(props) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [annotations, setAnnotations] = useState([]);
  const dispatch = useDispatch();
  const handleSubmit = () => {

    const regex = /^\S.*\S$/;

    if (!regex.test(question) || !question ||question.trim().length < 2) {
      toast.error("Please enter a question with at least 2 letters and no white spaces");
      return;
    }
    
    if (!regex.test(answer) || !answer || answer.trim().length < 2) {
      toast.error("Please enter a question with at least 2 letters and no white spaces");
      return;
    }

    let params = {
      question: question,
      answer: answer,
      annotations: annotations,
    };
    console.log(params, "paramforfaq");
    dispatch(
      addFaq({
        ...params,
        cb(res) {
          console.log("ll");
          if (res.status === 200) {
            console.log("successfullyaddedFaq");
            props?.close();
            props?.faqList();
          } else {
            console.log("there is some error in the faq");
          }
        },
      })
    );
  };

  //   const handleAnnotationsChange = (e) => {
  //     // Extracting annotations from input value and converting to array
  //     const newAnnotations = e.target.value.split(",").map((item) => item.trim());
  //     setAnnotations(newAnnotations);
  //   };

  return (
    <>
      <div>
        <div className="guideForm mb-3">
          <label className="">Question</label>
          <input
            class="form-control customFormControl"
            type="text"
            placeholder="Enter your Question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </div>

        <div className="guideForm mb-3">
          <label className="">Answer</label>
          <input
            class="form-control customFormControl"
            type="text"
            placeholder="Enter your Answer"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </div>

        {/* <div className="guideForm">
          <label>Annotations</label>
          <input
            className="form-control guideInput mt-2"
            type="text"
            placeholder="Enter Annotations separated by comma"
            value={annotations.join(",")} // Join the array elements into a string separated by comma
            onChange={handleAnnotationsChange} // Use handleAnnotationsChange to update the annotations state
          />
        </div> */}
        <div className="outer-btn mb-3">
          <button onClick={handleSubmit} className=" submit-btn">
            submit
          </button>
        </div>
      </div>
    </>
  );
}

export default CreateFaq;
