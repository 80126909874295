import React from "react";
import { Spinner } from "react-bootstrap";

function Loader() {
  return (
    <>
      <div className="d-flex justify-content-center mt-5 text-white ">
        <Spinner animation="border" variant="primary" />
      </div>
    </>
  );
}

export default Loader;
