import React from "react";
import * as images from "../../utilities/images";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import {
  deleteFaq,
  faqListAction,
  getFaqList,
} from "../../redux/slices/dashboard";
import CustomModal from "../Modal/CustomModal";
import CreateFaq from "../Modal/createFaqModal";
import UpdateFaq from "../Modal/upateFaqModal";
import Swal from "sweetalert2";
import { unwrapResult } from "@reduxjs/toolkit";
import FaqViewModal from "../Modal/faqViewModal";
import Loader from "../../common/Loader";

function Faq() {
  const dispatch = useDispatch();
  const [faq, setFaq] = useState("");
  const [key, setKey] = useState(Math.random());
  const [pageCount, setPageCount] = useState(0);
  const [loading,setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    data: "",
  });

  const handlePageClick = (e) => {
    console.log("hellogg");
    setPage(e.selected + 1);
    console.log(page, "page");
  };

  const deleteHandle = (val) => {
    Swal.fire({
      title: "Do you want to delete this ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          id: val?.id,
        };
        console.log(params, "paramsdelte");
        dispatch(
          deleteFaq({
            ...params,
            cb(res) {
              if (res.status === 200) {
                faqList();
              } else {
                console.log("there is some error in the faq");
              }
            },
          })
        );
      }
    });
  };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
      data: "",
    });
    setKey(Math.random());
  };
  const handleFaqModal = (flag, data) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
      data: data,
    });
    setKey(Math.random());
  };

  const faqList = () => {
    console.log("jj");
    const params = {
      need_pagination: true,
      page: page,
      limit: 10,
    };
    setLoading(true)

    dispatch(
      getFaqList({
        ...params,
        cb(res) {
          if (res?.data) {
            setFaq(res?.data?.payload?.data);
            setPageCount(res?.data?.payload?.total_pages);
            setLoading(false)
          } else {
            console.log("hello errror is comming");
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    faqList();
  }, []);
  useEffect(() => {
    faqList();
  }, [page]);

  return (
    <>
      <div className="content-wrapper categories">
        <div className="content-header">
          <div className="container-fluid">
            <div className="categories_group">
              <div className="categories_btn ms-auto">
                <button
                  className="loginBtnCommon btnYellow add_btn"
                  onClick={() => {
                    handleFaqModal("createFaq");
                  }}
                >
                  Create New
                </button>
              </div>
            </div>
          </div>
          {loading && <Loader/>}
          {/* <div className="searchSection">
            <input
              class="form-control searchInput"
              type="text"
              placeholder="Search by category..."
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                getAllCategories(1, 10, e.target.value);
              }}
            />
            <img
              src={images.searchImg}
              alt="searchImg"
              className="img-fluid searchBarIcon"
            />
          </div> */}

          <div className="table-responsive recentTable">
            <table class="table tables table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead">
                    SR.NO.
                  </th>
                  <th scope="col" className="recentHead">
                    Question
                  </th>
                  <th scope="col" className="recentHead">
                    Answer
                  </th>
                  <th scope="col" className="recentHead text-center">
                  Action
                  </th>
                </tr>
              </thead>

              {faq?.length > 0 ? (
                faq?.map((data, idx) => {
                const index = idx + 1 + (page - 1) * 10;  
                  return (
                    <tbody>
                      <tr>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">{index}</h4>
                        </td>
                        <td className="recentSubHead">
                          <div
                            className="nameProfile"
                            // onClick={(e) => handleGoToSubCategory(data)}
                          >
                            <p className="tableSubText underlineHover">
                              {data?.question}
                            </p>
                          </div>  
                        </td>
                        <td className="recentSubHead">
                          <div
                            className="nameProfile"
                            // onClick={(e) => handleGoToSubCategory(data)}
                          >
                            <p className="tableSubText underlineHover">
                              {data?.answer}
                            </p>
                          </div>
                        </td>

                        {/* {data?.options.map((option, idx) => {
                          return <div>{option?.text}</div>;
                        })} */}

                        <td className="recentSubHead text-center">
                          <button
                            className="activeUser mr-2"
                            onClick={() => {
                              handleFaqModal("updateFaq", data);
                            }}
                          >
                            Update
                          </button>
                          <button
                            className="activeUser mr-2"
                            onClick={() => deleteHandle(data)}
                          >
                            Delete
                          </button>
                          <button
                            className="activeUser"
                            onClick={() => handleFaqModal("viewFaq", data)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                
                <td><h3 className="text-center">No FAQ Found!</h3></td>
              
              )}
            </table>
          </div>



          <div className="col-12 ">
            <div className="customPagination mt-4">
              <div className="">
                {faq?.length > 0 ? (
                  <ReactPaginate
                    className="pagination"
                    // nextLabel={'Next ' + <span>{'>>'}</span>}
                    nextLabel={
                      <span>
                        Next <span className="double-arrow"></span>
                      </span>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    // pageRangeDisplayed={pageCount}  // Show only the starting 2 and last 2 pages
                    marginPagesDisplayed={2}
                    // pageRangeDisplayed={pageCount}
                    pageCount={pageCount}
                    // previousLabel={'<< Previous'}
                    previousLabel={
                      <span>
                        <span className="double-arrow h5"></span> Prev
                      </span>
                    }
                    // renderOnZeroPageCount={null}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <CustomModal
            key={key}
            show={modalDetail.show}
            backdrop="static"
            showCloseBtn={false}
            isRightSideModal={true}
            mediumWidth={false}
            className={
              modalDetail.flag === "createFaq"
                ? "commonWidth customContent"
                : ""
            }
            ids={modalDetail.flag === "createFaq" ? "createFaq" : ""}
            child={
              modalDetail.flag === "createFaq" ? (
                <CreateFaq
                  faqList={faqList}
                  // getAllGuides={guides}
                  close={() => handleOnCloseModal()}
                />
              ) : modalDetail.flag === "updateFaq" ? (
                <UpdateFaq
                  faq={modalDetail.data}
                  faqList={faqList}
                  close={() => handleOnCloseModal()}
                />
              ) : modalDetail.flag === "viewFaq" ? (
                <FaqViewModal faq={modalDetail.data} />
              ) : (
                ""
              )
            }
            header={
              modalDetail.flag === "createFaq" ? (
                <>
                  <h2 className="modal_Heading">Create FAQ</h2>
                  <p onClick={handleOnCloseModal} className="modal_cancel">
                    <img
                      src={images.modalCross}
                      className="ModalCance"
                      alt=""
                    />
                  </p>
                </>
              ) : modalDetail.flag === "updateFaq" ? (
                <>
                  <h2 className="modal_Heading">Update FAQ</h2>
                  <p onClick={handleOnCloseModal} className="modal_cancel">
                    <img
                      src={images.modalCross}
                      className="ModalCance"
                      alt=""
                    />
                  </p>
                </>
              ) : modalDetail.flag === "viewFaq" ? (
                <>
                  <h2 className="modal_Heading">View FAQ</h2>
                  <p onClick={handleOnCloseModal} className="modal_cancel">
                    <img
                      src={images.modalCross}
                      className="ModalCance"
                      alt=""
                    />
                  </p>
                </>
              ) : (
                ""
              )
            }
            onCloseModal={() => handleOnCloseModal()}
          />
        </div>
      </div>
    </>
  );
}

export default Faq;
