import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import {
  onErrorStopLoad,
  setAdminLogin,
  setAdminLogout,
  setForgotPassword,
  setEnterOtp,
  setrResendEnterOtp,
  setResetPassword,
  setchangePassword,
} from "../../slices/auth";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";

// Worker saga will be fired on USER_FETCH_REQUESTED actions

function* adminLogin(action) {
console.log("acrionnn",action);
  try {
    const resp = yield call(
      ApiClient.post,action.url = ApiPath.AuthApiPath.ADMIN_LOGIN,
      action.payload
    );
    console.log("resp",resp)
    if (resp.status) { 
      console.log( resp?.data?.payload?.accessToken,'respToken');
      localStorage.setItem( "adminAuthToken", resp?.data?.payload?.accessToken ? resp?.data?.payload?.accessToken : "" );
      yield put(setAdminLogin(resp.data.payload));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.message);
      console.log("qq")
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    console.log("hello",e.response);
    toast.error(e.response.data.msg);
  }
}

function* resetPassword(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.RESET_PASSWORD),
      action.payload
    );
    if (resp.status) {
      yield put(setResetPassword(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* enterOtp(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.ENTER_OTP),
      action.payload
    );
    if (resp.status) {
      yield put(setEnterOtp(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}




function* forgotPassword(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.FORGOT_PASSWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setForgotPassword(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    console.log(e)
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* changePassword(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.CHANGE_PASSWORD),
      (action.payload = action.payload)
    );
    if (resp.status) {
      yield put(setchangePassword(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    console.log(e)
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* resendEnterOtp(action) {
  console.log("resendEnterOtpdataaa",action)
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.AuthApiPath.RSEND_ENTER_OTP),
      (action.payload = action.payload)
    );
    console.log(resp,"ggggg")
    if (resp.status) {
      yield put(setrResendEnterOtp(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.msg);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}


function* adminLogout(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.AuthApiPath.LOGOUT_ADMIN),
      action.payload
    );
    if (resp.status) {
      localStorage.removeItem("adminAuthToken");
      yield call(action.payload.cb, (action.res = resp));
      toast.success(resp.data.msg);
      yield put(setAdminLogout());
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(e.response.data.msg);
  }
}

function* authSaga() {
  yield all([takeLatest("auth/adminLogin", adminLogin)]);
  yield all([takeLatest("auth/adminLogout", adminLogout)]);
  yield all([takeLatest("auth/forgotPassword", forgotPassword)]);
  yield all([takeLatest("auth/enterOtp", enterOtp)]);
  yield all([takeLatest("auth/resetPassword", resetPassword)]);
  yield all([takeLatest("auth/changePassword", changePassword)]);
  yield all([takeLatest("auth/resendEnterOtp",resendEnterOtp)])
}

export default authSaga;
