import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addFaq, updateFaq } from "../../redux/slices/dashboard";
import { toast } from "react-toastify";


function UpdateFaq(props) {
  console.log(props, "propsfaqlist");
  const [question, setQuestion] = useState(
    props?.faq?.question ? props?.faq?.question : ""
  );
  const [answer, setAnswer] = useState(
    props?.faq?.answer ? props?.faq?.answer : ""
  );
  const [annotations, setAnnotations] = useState([]);
  const [id, setId] = useState(props?.faq?.id ? props?.faq?.id : "");
  const dispatch = useDispatch();
  const handleSubmit = (e) => {

    const regex = /^\S.*\S$/;

    if (!regex.test(question) || !question ||question.trim().length < 2) {
      toast.error("Please enter a question with at least 2 letters and no white spaces");
      return;
    }
    
    if (!regex.test(answer) || !answer || answer.trim().length < 2) {
      toast.error("Please enter a question with at least 2 letters and no white spaces");
      return;
    }

    e.preventDefault();
    let params = {
      id: id,
      question: question,
      answer: answer,
      annotations: annotations,
    };
    console.log(params, "paramforfaq");
    dispatch(
      updateFaq({
        ...params,
        cb(res) {
          console.log("kk");
          if (res.status === 200) {
            props?.close();
            props?.faqList();
          } else {
            console.log("there is some error in the faq");
          }
        },
      })
    );
  };

  return (
    <>
      <div>
        <div className="guideForm mb-3">
          <label className="form-label">Question</label>
          <input
            class="form-control customFormControl"
            type="text"
            placeholder="Enter Annotations"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </div>

        <div className="guideForm mb-3">
          <label className="form-label">Answer</label>
          <input
            class="form-control customFormControl"
            type="text"
            placeholder="Enter your Answer"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </div>
        {/* 
        <div className="guideForm">
          <label>Annotations</label>
          <input
            className="form-control guideInput mt-2"
            type="text"
            placeholder="Enter Annotations separated by comma"
            value={annotations.join(",")} // Join the array elements into a string separated by comma
            onChange={handleAnnotationsChange} // Use handleAnnotationsChange to update the annotations state
          />
        </div> */}
        <div className="outer-btn mb-3">
        <button onClick={handleSubmit} className=" submit-btn">submit</button>
        </div>
      </div>
    </>
  );
}

export default UpdateFaq;
