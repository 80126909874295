import * as images from "../../utilities/images";
import deleteaction from "../../components/Images/deleteaction.png";
import CustomModal from "../Modal/CustomModal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  articleCategory,
  behaviourReports,
  deleteArticle,
  screeningSymptoms,
  setSymptomOptions,
  symptomOptions,
} from "../../redux/slices/dashboard";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ScreeningDetails from "./screeningDetails";
import Loader from "../../common/Loader";

const Screening = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading,setLoading]= useState(false);
  const [screening, setScreening] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleUserProfile = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };
  // page = 1, limit = 10, search = searchValue
  const AllScreeningSymptoms = () => {
    setLoading(true);
    dispatch(
      screeningSymptoms({
        cb(res) {
          if (res?.data) {
            console.log(res?.data,"allScreeningData")
            setScreening(res?.data?.payload);
            setLoading(false);
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    AllScreeningSymptoms();
  }, []);

  return (
    <>
      <div className="content-wrapper categories">
        <div className="content-header">
          <div className="container-fluid">
            {/* <div className="categories_group">
                <div className="categories_txt">
                  <h1 className="mainHead32">Screening Symptoms</h1>
                </div>
                <div className="categories_btn ms-auto">
                  <button 
                    className="loginBtnCommon btnYellow add_btn"
                    onClick={() => {
                      handleUserProfile("createScreening");
                    }}
                  >
                    Create New
                  </button>
                </div>
              </div> */}
          </div>
          {/* <div className="searchSection">
            <input
              class="form-control searchInput"
              type="text" 
              placeholder="Search by category..."
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                getAllCategories(1, 10, e.target.value);
              }}
            />
            <img
              src={images.searchImg}
              alt="searchImg"
              className="img-fluid searchBarIcon"
            />
          </div> */}
          {loading && <Loader/>}

          <div className="table-responsive recentTable">
            <table class="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead">
                    SR. NO.
                  </th>
                  <th scope="col" className="recentHead">
                    Title
                  </th>
                  <th scope="col" className="recentHead">
                    Description
                  </th>
                  <th scope="col" className="recentHead">
                    Action
                  </th>
                </tr>
              </thead>

              {screening?.length > 0 ? (
                screening?.map((screen, idx) => {
                  return (
                    <tbody>
                      <tr>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">{idx + 1}</h4>
                        </td>
                        <td className="recentSubHead">
                          <div
                            className="nameProfile"
                            // onClick={(e) => handleGoToSubCategory(data)}
                          >
                            <p className="tableSubText underlineHover">
                              {screen?.key}
                            </p>
                          </div>
                        </td>
                        <td className="recentSubHead">
                          <div
                            className="nameProfile"
                            // onClick={(e) => handleGoToSubCategory(data)}
                          >
                            <p className="tableSubText underlineHover desScreening">
                              {screen?.data[0]?.question}
                            </p>
                          </div>
                        </td>
                        <td className="recentSubHead">
                          <div
                            className="nameProfile"
                            // onClick={(e) => handleGoToSubCategory(data)}
                          >
                            <p className="tableSubText underlineHover">
                              <Link
                                className="nameProfile"
                                to={
                                  screen.key === "recallScreening"
                                    ? "/recallScreening"
                                    : screen.key === "orientationScreening"
                                    ? "/orientationScreening"
                                    : screen.key === "wordScreening"
                                    ? "/wordScreening"
                                    : ""
                                }
                                state={{ screening: screening }}
                              >
                                Details
                              </Link>
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <h3 className="text-center">No Screening Found!</h3>
              )}
            </table>
          </div>
        </div>
      </div>
      {/* <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={false}
          isRightSideModal={true}
          mediumWidth={false}
          className={
            modalDetail.flag === "createcategory"
              ? "commonWidth customContent"
              : ""
          }
          ids={modalDetail.flag === "createcategory" ? "createCategoryModal" : ""}
          child={
            modalDetail.flag === "createcategory" ? (
              <CreateCategoryModal
                getAllCategories={getAllCategories}
                close={() => handleOnCloseModal()}
              />
            ) : (
              ""
            )
          }
          header={
            modalDetail.flag === "createcategory" ? (
              <>
                <h2 className="modal_Heading">Create New</h2>
                <p onClick={handleOnCloseModal} className="modal_cancel">
                  <img src={images.modalCross} className="ModalCance" alt="" />
                </p>
              </>
            ) : (
              ""
            )
          }
          onCloseModal={() => handleOnCloseModal()}
        /> */}
    </>
  );
};
export default Screening;
