import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import CustomModal from "../Modal/CustomModal";
import * as images from "../../utilities/images";
import CreateSubOption from "../Modal/createSubOption";
import CreateOptionModal from "../Modal/createOptionModal";
import CreateOption from "../Modal/createOptionModal";
import { classNames } from "@react-pdf-viewer/core";
import { getOneScreeningSymptoms } from "../../redux/slices/dashboard";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function RecallScreening() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const pathName = window.location.pathname;
  const parts = pathName.split("/");
  const pathkey = parts[parts.length - 1];
  console.log(pathkey, "pathKey");
  const dispatch = useDispatch();
  const [recall, setRecall] = useState();
  const [key, setKey] = useState(Math.random());
  const screeningData = state?.screening.find(
    (item) => item.key === "recallScreening"
  );
  console.log(screeningData,"screeningDatamy")


  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleCreateModal = (
    flag,
    optionTitle,
    optionId,
    question,
    questionId,
    innerOptionText,
    innerOptionId
  ) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
      optionTitle: optionTitle,
      optionId: optionId,
      Question: question,
      questionId: questionId,
      innerOptionText: innerOptionText,
      innerOptionId: innerOptionId,
    });
    setKey(Math.random());
  };

  const oneScreening = () => {
    let params = {
      screeningId: screeningData?.id,
      key: pathkey,
    };
    console.log(params, "paramsDatas");
    dispatch(
      getOneScreeningSymptoms({
        ...params,
        cb(res) {
          console.log(res,'myyyyyyyyyyyyyyyyyyyyyy');
          if (res?.status === 200) {
            console.log(res?.data?.payload, "myresponseData");
            setRecall(res?.data?.payload);
          } else {
            toast.error("Something Went Wrong");
          }
        },
      })
    );
  };
  useEffect(() => {
    oneScreening();
  },[]);

  return (
    <>
    <div className="content-wrapper categories">
        <div className="content-header">
          <div className="container-fluid">
      <img
        onClick={() => {
          navigate("/screening");
        }}
        src={images.arrowMain}
        alt="arrowMainimg"
        className="img-fluid pb-3 me-3 cursor"
      />
     <h1 className="text-capital d-inline-block">Title: RecallScreening</h1>
      {recall?.data && recall?.data?.length > 0 ? (
        <>
          {recall.data.map((item, i) => (
            <div key={i}>
              <p className="">Question: {item.question}</p>
              <button
                onClick={() => {
                  handleCreateModal("createOption");
                }}
                className="activeUser mb-3"
              >
                create Option
              </button>
              {item.data && item.data.length > 0 && (
                <div>
                  {/* <p className="">Options:</p> */}

                  {item.data.map((option, index) => (
                    <div key={index}>
                      <p className="fw-bold mainOption">
                        {" "}
                        {index + 1}) <span className="me-3">{option.title}</span>
                        <button
                          className="activeUser"
                          onClick={() => {
                            handleCreateModal(
                              "createSubOption",
                              option.title,
                              option?.id,
                              item.question,
                              screeningData?.id
                            );
                          }}
                        >
                          Create Sub Option
                        </button>
                        {/* <button
                          onClick={() => {
                            handleCreateModal(
                              "updateOption",
                              option.title,
                              option?.id,
                              item.question,
                              screeningData?.id
                            );
                          }}
                        >
                          Update Option
                        </button> */}
                      </p>
                      {option.options && option.options.length > 0 && (
                        <div className="mb-4">
                          <ul className="option_">
                            {option.options.map((innerOption, innerIndex) => (
                              <li key={innerIndex} className="">
                                {" "}
                                <span className="me-2">
                                  Options {innerIndex + 1} :{" "}
                                </span>{" "}
                                <span className="me-3">{innerOption.text}</span>
                                <button
                                  className="activeUser"
                                  onClick={() => {
                                    handleCreateModal(
                                      "updateSubOption",
                                      option.title,
                                      option?.id,
                                      item.question,
                                      item.id,
                                      innerOption.text,
                                      innerOption.id
                                    );
                                  }}
                                >
                                  Update Option
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
          
        </>
      ) : null}
</div>
          </div>
          </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createSubOption"
            ? "commonWidth customContent"
            : ""
        }
        ids={modalDetail.flag === "createSubOption" ? "createSubOption" : ""}
        child={
          modalDetail.flag === "createSubOption" ? (
            <CreateSubOption
              option={false}
              optionId={modalDetail.optionId}
              screeningData={recall}
              optionTitle={modalDetail.optionTitle}
              question={modalDetail.Question}
              dataId={modalDetail.dataId}
              oneScreening={oneScreening}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "createOption" ? (
            <CreateOption
              option={true}
              screeningData={recall}
              oneScreening={oneScreening}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "updateOption" ? (
            <CreateOption
              isEdit={true}
              optionId={modalDetail.optionId}
              screeningData={recall}
              oneScreening={oneScreening}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "updateSubOption" ? (
            <CreateSubOption
              isEdit={true}
              optionId={modalDetail.optionId}
              questionId={modalDetail.questionId}
              question={modalDetail.Question}
              innerOptionText={modalDetail.innerOptionText}
              innerOptionId={modalDetail.innerOptionId}
              screeningData={recall}
              oneScreening={oneScreening}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "createSubOption" ? (
            <>
              <h2 className="modal_Heading">Create Sub Option</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "createOption" ? (
            <>
              <h2 className="modal_Heading">Create Option</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "updateOption" ? (
            <>
              <h2 className="modal_Heading">Update Option</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "updateSubOption" ? (
            <>
              <h2 className="modal_Heading">Update Sub Option</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
}

export default RecallScreening;
