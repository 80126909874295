import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createSubOption } from "../../redux/slices/dashboard";
import { toast } from "react-toastify";

function CreateOption(props) {
  const dispatch = useDispatch();
  const toastId = React.useRef(null);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [id, setId] = useState(props?.screeningData?.id);

  const screeningData = props?.screeningData?.data;

  const options = screeningData[0]?.data;

  const handleOptions = (e) => {
    if (!text.trim() || /^\s*$/.test(text) || text.length < 2) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Option should not be empty, consist only of whitespace, or be less than 2 characters"
        );
      }
      return;
    }
    let params;
    params = {
      key: "recallScreening",
      id: id.toString(),
      data: [
        {
          id: "1",
          data: [
            ...options,
            {
              id: Date.now().toString(),
              title: text,
              options: [],
            },
          ],
          question: props?.screeningData?.data[0]?.question,
        },
      ],
    };

    console.log(params, "hh");

    dispatch(
      createSubOption({
        ...params,
        cb(res) {
          if (res.status === 200) {
            toast.success("Data Created Successfully");
            props?.close();
            props?.oneScreening();
          } else {
            toast.error("Something Went Wrong");
          }
        },
      })
    );
  };
  return (
    <div>
      <div className="mb-3">
        <label>Enter the text for options</label>
        <input
          class="form-control customFormControl"
          type="text"
          placeholder="Enter your Text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <div className="outer-btn mb-3">
        <button onClick={(e) => handleOptions(e)} className="submit-btn">
          submit
        </button>
      </div>
    </div>
  );
}

export default CreateOption;
