import * as images from "../../utilities/images";
import deleteaction from "../../components/Images/deleteaction.png";
import CustomModal from "../Modal/CustomModal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  articleCategory,
  behaviourReports,
  deleteArticle,
  deleteGuide,
  setSymptomOptions,
  symptomOptions,
} from "../../redux/slices/dashboard";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import CreateGuides from "../Modal/createGuideModal";
import ViewGuide from "../Modal/viewGuideModal";
import UpdateGuide from "../Modal/updateGuideModal";
import Loader from "../../common/Loader";

const Guide = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [guides, setGuides] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [key, setKey] = useState(Math.random());
  const [admin, setAdmin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handlePageClick = (e) => {
    console.log("hellogg");
    setPage(e.selected + 1);
    console.log(page, "page");
  };

  // page = 1, limit = 10, search = searchValue
  const getAllGuides = () => {
    const params = {
      isAdmin: admin,
      page: page,
      limit: 10,
    };

    console.log(params);
    setLoading(true);

    dispatch(
      behaviourReports({
        ...params,
        cb(res) {
          if (res?.data) {
            setGuides(res?.data?.payload?.data);
            setPageCount(res?.data?.payload?.total_pages);
            setLoading(false);
          } else {
            toast.error("Something Went Wrong");
          }
        },
      })
    );
  };

  const handleGoToDetailPage = (data) => {
    navigate(`/guideDetail/${data?.id}`, {
      state: {
        oneGuide: data,
      },
    });
  };

  const handleCreateModal = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };
  // const handleGuideModal = (flag, data) => {
  //   setModalDetail({
  //     show: true,
  //     flag: flag,
  //     type: flag,
  //     data: data,
  //   });
  //   setKey(Math.random());
  // };

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  useEffect(() => {
    getAllGuides();
  }, []);
  useEffect(() => {
    getAllGuides();
  }, [page]);

  return (
    <>
      <div className="content-wrapper categories">
        <div className="content-header">
          <div className="container-fluid">
            <div className="categories_group">
              <div className="categories_btn ms-auto">
                <button
                  className="loginBtnCommon btnYellow add_btn"
                  onClick={() => {
                    handleCreateModal("createGuide");
                  }}
                >
                  Create New
                </button>
              </div>
            </div>
          </div>

          {/* <div className="searchSection">
              <input
                class="form-control searchInput"
                type="text"
                placeholder="Search by category..."
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  getAllGuides(1, 10, e.target.value);
                }}
              />
              <img
                src={images.searchImg}
                alt="searchImg"
                className="img-fluid searchBarIcon"
              />
            </div> */}
          {loading && <Loader />}

          <div className="table-responsive recentTable ">
            <table class="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead">
                    SR. NO.
                  </th>
                  <th scope="col" className="recentHead">
                    Title
                  </th>
                  <th scope="col" className="recentHead">
                    Description
                  </th>
                  <th scope="col" className="recentHead text-center">
                    Actions
                  </th>
                </tr>
              </thead>

              {guides?.length > 0 ? (
                guides?.map((guide, idx) => {
                  const index = idx + 1 + (page - 1) * 10;
                  return (
                    <tbody>
                      <tr>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">{index}</h4>
                        </td>
                        <td className="recentSubHead">
                          <div className="nameProfile">
                            <p className="tableSubText underlineHover">
                              {guide?.title}
                            </p>
                          </div>
                        </td>
                        <td className="recentSubHead">
                          <div className="nameProfile">
                            <p className="tableSubText underlineHover">
                              {guide?.description}
                            </p>
                          </div>
                        </td>

                        <td className="recentSubHead text-center">
                          <button
                            // onClick={() => handleGuideModal("viewGuide", guide)}
                            type="button"
                            className="activeUser"
                            onClick={(e) => handleGoToDetailPage(guide)}
                          >
                            View
                          </button> 
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <h3 className="text-center">No Guides Found!</h3>
              )}
            </table>
          </div>
          <div className="col-12 ">
            <div className="customPagination mt-4">
              <div className="">
                {guides?.length > 0 ? (
                  <ReactPaginate
                    className="pagination"
                    // nextLabel={'Next ' + <span>{'>>'}</span>}
                    nextLabel={
                      <span>
                        Next <span className="double-arrow"></span>
                      </span>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    // pageRangeDisplayed={pageCount}  // Show only the starting 2 and last 2 pages
                    marginPagesDisplayed={2}
                    // pageRangeDisplayed={pageCount}
                    pageCount={pageCount}
                    // previousLabel={'<< Previous'}
                    previousLabel={
                      <span>
                        <span className="double-arrow h5"></span> Prev
                      </span>
                    }
                    // renderOnZeroPageCount={null}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createGuide" ? "commonWidth customContent" : ""
        }
        ids={modalDetail.flag === "createGuide" ? "createGuide" : ""}
        child={
          modalDetail.flag === "createGuide" ? (
            <CreateGuides
              getAllGuides={getAllGuides}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "viewGuide" ? (
            <ViewGuide
              guide={modalDetail.data}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "createGuide" ? (
            <>
              <h2 className="modal_Heading">Create Guide</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "viewGuide" ? (
            <>
              <h2 className="modal_Heading">Guide View</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "updateGuide" ? (
            <>
              <h2 className="modal_Heading">Guide Update</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};
export default Guide;
