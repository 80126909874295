import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  loading: false,
  
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    adminLogin: (state) => {
      state.loading = true;
    },
    setAdminLogin: (state, action) => {
      state.loading = false;
      // state.loginData = action.payload;
    },
    forgotPassword: (state) => {
      state.loading = true;
    },
    setForgotPassword: (state, action) => {
      state.loading = false;
      state.forgotPassword = action.payload;
    },

    resetPassword: (state) => {
      state.loading = true;
    },
    setResetPassword: (state, action) => {
      state.loading = false;
    },
    changePassword:(state,action)=>{
      state.loading = true;
    },
    setchangePassword:(state,action)=>{
      state.loading=false;
      state.changePassword = action.payload
    },
    resendEnterOtp: (state) => {
      state.loading = true;
    },
    setrResendEnterOtp: (state, action) => {
      state.loading = false;
    },
    enterOtp: (state) => {
      state.loading = true;
    },
    setEnterOtp: (state, action) => {
      state.loading = false;
      state.enterOtp = action.payload;
    },
    // changePassword:(state,action){
    //   state.loading = false;

    // },
    adminLogout: (state) => {
      state.loading = true;
    },
    setAdminLogout: (state, action) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  forgotPassword,
  setForgotPassword,
  adminLogin,
  setAdminLogin,
  onErrorStopLoad,
  adminLogout,
  setAdminLogout,
  enterOtp,
  setEnterOtp,
  resendEnterOtp,
  setrResendEnterOtp,
  changePassword,
  setchangePassword,
  resetPassword,
  setResetPassword,
} = authSlice.actions;

export default authSlice.reducer;
