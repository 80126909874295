import React, { useEffect, useState } from "react";
import * as images from "../../utilities/images";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateUserStatus, userList } from "../../redux/slices/dashboard";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Loader from "../../common/Loader";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userLists, setUserList] = useState([]);
  const [loading,setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  

  const handlePageClick = (e) => {
    setPage(e.selected + 1);
  };
  const getallUserList = () => {
    const params = {
      page: page,
      limit: 10,
    };

    setLoading(true)
    dispatch(
      userList({
        ...params,
        cb(res) {
          if (res?.data) {
            setUserList(res?.data?.payload?.data);
            setPageCount(res?.data?.payload?.total_pages);
            setLoading(false)
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };
  // const handleFilterData = (status) => {
  //   getallUserList(1, 10, searchValue, status);
  //   setStatus(status);
  //   setActive(status);
  // };
  const handleGoToDetailPage = (data) => {
    navigate(`/userDetail/${data?.id}`);
  };

  // const handleChangeStatus = (id, status) => {
  //   let params = {
  //     userId: id,
  //     status: status,
  //   };
  //   console.log(params, "parsmshhh");
  //   dispatch(
  //     updateUserStatus({
  //       ...params,
  //       cb(res) {
  //         console.log(res.data.payload, "status");
  //         if (res?.data) {
  //           setStatus(res?.data?.payload[0]);
  //         } else {
  //           toast.error("something went wrong");
  //         }
  //       },
  //     })
  //   );
  // };

  const handleChangeStatus = (id, currentStatus) => {
    const newStatus = currentStatus === 0 ? 1 : 0;
    let params = {
      userId: id,
      status: newStatus,
    };
    dispatch(
      updateUserStatus({
        ...params,
        cb(res) {
          if (res?.data) {
            // Update userLists with the new status
            setUserList((prevUserLists) => {
              return prevUserLists.map((user) => {
                if (user.id === id) {
                  return { ...user, status: newStatus };
                }
                return user;
              });
            });
            console.log(userLists, "UserListdataaaa");

            // Show success toast message
            toast.success("User status updated successfully");
          } else {
            // Show error toast message
            toast.error("Something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    getallUserList();
  }, [page]);

  useEffect(() => {
    getallUserList();
  }, []);
  return (
    <>
      <div className="content-wrapper ">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="mainHead32">Users</h1>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="searchSection">
          <input
            class="form-control searchInput"
            type="text"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              getallUserList(1, 10, e.target.value, status);
            }}
          />
          <img
            src={images.searchImg}
            alt="searchImg"
            className="img-fluid searchBarIcon"
          />
        </div> */}
        <div className="recentUserSec">
          {/* <div className="userListStatus">
            <h4
              className={
                active === "" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => handleFilterData("")}
            >
              All
            </h4>
            <h4
              className={
                active === "Active" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => handleFilterData("Active")}
            >
              Active
            </h4>
            <h4
              className={
                active === "Inactive" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => handleFilterData("Inactive")}
            >
              Deactive
            </h4>
            <h4
              className={
                active === "Blocked" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => handleFilterData("Blocked")}
            >
              Block
            </h4>
          </div> */}

          {loading && <Loader/>}
          <div className="table-responsive recentTable userRecentTable">
            <table class="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead">
                    SR. NO.
                  </th>
                  <th scope="col" className="recentHead">
                    First Name
                  </th>
                  <th scope="col" className="recentHead">
                    Last Name
                  </th>
                  <th scope="col" className="recentHead">
                    Email
                  </th>
                  <th scope="col" className="recentHead text-center">
                    Patient Info
                  </th>

                  <th scope="col" className="recentHead ">
                    Status
                  </th>
                </tr>
              </thead>

              {userLists?.length > 0 ? (
                userLists?.map((data, idx) => {
                  const index = idx + 1 + (page - 1) * 10;
                  return (
                    <tbody>
                      <tr>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">{index}</h4>
                        </td>
                        <td className="recentSubHead">
                          <div className="nameProfile">
                            {/* <img
                              src={images.profileImg}
                              className="img-fluid profileImg_"
                              alt="profileImg"
                            /> */}
                            <h4 className="tableSubText">{data?.first_name}</h4>
                          </div>
                        </td>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">{data?.last_name}</h4>
                        </td>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">
                            {/* {data?.userInfo?.dialCode} {data?.userInfo?.phoneNo}
                             */}
                            {/* {data?.payload?.data}
                             */}
                            {data?.email}
                          </h4>
                        </td>
                        <td className="recentSubHead text-center">
                          <button
                            className="eyeBtn"
                            type="button"
                            onClick={(e) => handleGoToDetailPage(data)}
                          >
                            <img
                              src={images.eyeImg}
                              className="img-fluid "
                              alt="eyeImg"
                            />
                          </button>
                        </td>
                        <td>
                          <button
                            className={`${
                              data.status === 0 ? "activeUser" : "dActiveUser"
                            }`}
                            onClick={() =>
                              handleChangeStatus(data.id, data.status)
                            }
                          >
                            {data.status === 0 ? "Activate" : "Deactivate"}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <h3 className="text-center">No Users Found!</h3>
              )}
            </table>
          </div>
          <div className="col-12 ">
            <div className="customPagination mt-4">
              <div className="">
                {userLists?.length > 0 ? (
                  <ReactPaginate
                    className="pagination"
                    // nextLabel={'Next ' + <span>{'>>'}</span>}
                    nextLabel={
                      <span>
                        Next <span className="double-arrow"></span>
                      </span>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    // pageRangeDisplayed={pageCount}  // Show only the starting 2 and last 2 pages
                    marginPagesDisplayed={2}
                    // pageRangeDisplayed={pageCount}
                    pageCount={pageCount}
                    // previousLabel={'<< Previous'}
                    previousLabel={
                      <span>
                        <span className="double-arrow h5"></span> Prev
                      </span>
                    }
                    renderOnZeroPageCount={null}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
