import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createQuestion, createSubOption } from "../../redux/slices/dashboard";
import { classNames } from "@react-pdf-viewer/core";
import { toast } from "react-toastify";

function CreateOrientationQuestion(props) {
  console.log(props, "newprops");
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const toastId = React.useRef(null);

  const handleOptions = (e) => {

    if (!text.trim() || /^\s*$/.test(text) || text.length < 2) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Option should not be empty, consist only of whitespace, or be less than 2 characters");
      }
      return; 
    }
    const questionsData = props?.screeningData?.data || [];

    if (props?.isEdit) {
      const questionId = props?.questionId;

      const question = props?.screeningData?.data.find(
        (item) => item.id === questionId
      );

      const options = question?.options || [];
      console.log(options, "options");

      // const newQuestion = {
      //   id: props?.questionId,
      //   options: options,

      //   question: text,
      // };

      // const updatedQuestionsData = [...questionsData, newQuestion];

      const questionIndex = questionsData.findIndex(
        (question) => question.id === props?.questionId
      );

      const updatedQuestion = {
        id: props?.questionId,
        options: options,
        question: text,
      };

      const updatedQuestionsData = [...questionsData];
      if (questionIndex !== -1) {
        updatedQuestionsData[questionIndex] = updatedQuestion;
      } else {
        updatedQuestionsData.push(updatedQuestion);
      }

      let params = {
        key: "orientationScreening",
        id: props?.screeningData?.id.toString(),
        data: updatedQuestionsData,
      };
      console.log(params, "updateparams");

      dispatch(
        createQuestion({
          ...params,
          cb(res) {
            if (res.status === 200) {
              toast.success("Data Updated Successfully");
              props?.close();
              props?.oneScreening();
            } else {
              console.log("error");
            }
          },
        })
      );
    } else {
      const newQuestion = {
        id: Date.now().toString(),
        options: [],
        question: text,
      };
      const updatedQuestionsData = [...questionsData, newQuestion];

      let params = {
        key: "orientationScreening",
        id: props?.screeningData?.id.toString(),
        data: updatedQuestionsData, // Pass updatedQuestionsData directly
      };

      console.log(params, "myquestionParams");

      dispatch(
        createQuestion({
          ...params,
          cb(res) {
            if (res.status === 200) {
              toast.success("Data Created Successfully");
              props?.close();
              props?.oneScreening();
            } else {
              toast.error("Something Went Wrong");
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    setText(props?.question ? props?.question : "");
  }, []);

  return (
    <>
      <div className="mb-3">
        <label>Enter the text for Question</label>
        <input
          class="form-control customFormControl"
          type="text"
          placeholder="Enter your Text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <div className="outer-btn mb-3">
        <button onClick={(e) => handleOptions(e)} className="submit-btn">
          submit
        </button>
      </div>
    </>
  );
}

export default CreateOrientationQuestion;
