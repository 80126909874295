import React from "react";
import { Link } from "react-router-dom";
import * as images from "../../src/utilities/images";

const Sidebar = () => {
  const pathName = window.location.pathname;

  return (
  <>
      <aside className="sidebarSil main-sidebar sideBar-bg sidebar-dark-primary elevation-4 main">
        <Link to="/users" className="brand-link">
          <img
            src={images.britelogo}
            className="img-fluid"
            alt="Brand Logo"
          />
        </Link>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="true"
            >
              {/* <li className="nav-item sideheading">
                <Link
                  to="/home"
                  className={
                    ["/home"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img
                    src={
                      ["/home"].includes(pathName)
                        ? images.actDash
                        : images.Dashboard
                    }
                    alt="side-icons"
                  />
                  <p className="ms-2">Dashboard</p>
                </Link>
              </li> */}

              <li className="nav-item sideheading">
                <Link
                  to="/users"
                  className={
                    ["/users"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img
                    src={
                      ["/users"].includes(pathName)
                        ? images.actarticle
                        : images.articles
                    }
                    alt="side-icons"
                  />
                  <span className="ms-2">Users</span>
                </Link>
              </li>

              <li className="nav-item sideheading">
                {/* <Link
                  to="/patients"
                  className={
                    ["/patients"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={["/patients"].includes(pathName) ? images.actdraft : images.draft} alt="side-icons" />
                  <span className="ms-2">Patients Listing</span>
                </Link> */}
              </li>

              <li className="nav-item sideheading">
                <Link
                  to="/symptoms"
                  className={
                    ["/symptoms"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={["/symptoms"].includes(pathName) ? images.actticktes : images.tickets} alt="side-icons" />
                  <span className="ms-2">Symptoms</span>
                </Link>
              </li>



              <li className="nav-item sideheading">
                <Link
                  to="/guides"
                  className={
                    ["/guides"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={["/guides"].includes(pathName) ? images.actticktes : images.tickets} alt="side-icons" />
                  <span className="ms-2">Guides</span>
                </Link>
              </li>


              <li className="nav-item sideheading">
                <Link
                  to="/screening"
                  className={
                    ["/screening"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={["/screening"].includes(pathName) ? images.actticktes : images.tickets} alt="side-icons" />
                  {/* <i class="fa-solid fa-shield-halved"></i> */}
                  <span className="ms-2">Screening Symptoms</span>
                </Link>
              </li>

              <li className="nav-item sideheading">
                <Link
                  to="/faq"
                  className={
                    ["/faq"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={["/faq"].includes(pathName) ? images.actticktes : images.tickets} alt="side-icons" />
                  <p className="ms-2">FAQ </p>
                </Link>
              </li> 
              <li className="nav-item sideheading">
                <Link
                  to="/privacy"
                  className={
                    ["/privacy"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <img src={["/privacy"].includes(pathName) ? images.actticktes : images.tickets} alt="side-icons" />
                  <p className="ms-2">Privacy Policy </p>
                </Link>
              </li> 
             
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
