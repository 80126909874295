// import React from "react";
// import { Outlet, Navigate } from "react-router-dom";

// const ProtectedRoute = ({ isLoggedIn }) => {
//   return <>{isLoggedIn ? <Outlet /> : <Navigate to="/" />}</>;
// };

// export default ProtectedRoute;
import { Navigate, Outlet } from "react-router-dom";

const PrivateCheck = ({ auth }) => {
    const authToken = localStorage.getItem("adminAuthToken") ? true : false;

    return (
        <>
            {
                (authToken && auth) ? <Outlet /> :
                    (authToken && !auth) ? <Navigate to="/users" />   :
                        (!authToken && !auth) ? <Outlet /> : <Navigate to="/" />
            }
        </>
    )
}

export default PrivateCheck;
