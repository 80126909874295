import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { addSymptomOption } from "../../redux/slices/dashboard";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

function CreateSymptomModal(props) {
  console.log(props, "getAllSymptomOptions");
  const [text, setText] = useState("");
  const [id, setID] = useState("");
  const [key, setKey] = useState("");
  const [status, setStatus] = useState(1);
  const [title, setTitle] = useState("");
  const [selectedSymptom, setSelectedSymptom] = useState(null);
  const [optionid, setOptionId] = useState("");
  const toastId = React.useRef(false);
  const dispatch = useDispatch();
  const getallSmptoms = props?.getAllSymptoms;

  const handleSymptomChange = (selectedOption) => {
    setSelectedSymptom(selectedOption);
    console.log(selectedOption, "selectedOption");
    if (selectedOption) {
      const selectedkey = selectedOption.value;
      console.log(selectedkey, "selectedddIddddd");
      setKey(selectedkey);
    } else {
      setKey("");
    }
  };

  const handleSubmit = (e) => {
    if (!text.trim() || /^\s*$/.test(text) || text.length < 2) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Option should not be empty, consist only of whitespace, or be less than 2 characters");
      }
      return; 
    }
    if(!selectedSymptom){
      toast.error("Please Select the Symptom");
    }

      if (key && selectedSymptom) {
        const selectedSymptomInfo = getallSmptoms.find(
          (symptom) => symptom.key === key
        );
    
        // if (selectedSymptomInfo) {
          const { id, title } = selectedSymptomInfo;
          setID(id);
          setTitle(title);
        // }
    
      // const optionId = uuidv4();
      const optionId = uuidv4();
      const newOption = {
        id: optionId,
        text: text,
        status: status,
      };
      let params = {
        key: key,
        title: title,
        id: id,
        options: [...selectedSymptomInfo.options, newOption], // Append the new option
      };
      
      dispatch(
        addSymptomOption({
          ...params,
          cb(res) {
            if (res.status === 200) {
              toast.success("option added succesfully");
              props?.close();
              props.getSymptom();
            } else {
            toast.error("Something Went Wrong");
            }
          },
        })
      );
    }
  };

  return (
    <>
      <div className="mb-3">
        <label>Enter the text for options</label>
        <input
          class="form-control customFormControl"
          type="text"
          placeholder="Enter your Text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>

      <div>
        <label>Select the Symptom</label>

        <Select
        className="mb-4"
          value={selectedSymptom}
          onChange={handleSymptomChange}
          options={
            Array.isArray(getallSmptoms)
              ? getallSmptoms.map((symptom) => ({
                  value: symptom.key,
                  label: symptom.title,
                }))
              : []
          }
          placeholder="Select a symptom"
        />
      </div>

      <div className="outer-btn mb-3">
        <button onClick={handleSubmit} className="submit-btn">Submit</button>
      </div>
    </>
  );
}

export default CreateSymptomModal;
