import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";
import { onErrorStopLoad } from "../../slices/auth";
import {
  setLogoutAdmin,
  setUserList,
  setPatientList,
  setSymptomOptions,
  setBehaviourReports,
  setScreeningSymptoms,
  setUpdateUserStatus,
  setUpdatePatientStatus,
  setPrivacyPolicy,
  setPrivacyPolicyUpdate,
  setImageUpload,
  setBehaviourReportsAll,
  setgetFaqList,
  setCreateGuide,
  setAddFaq,
  setUpdateFaq,
  setDeleteFaq,
  setDeleteGuide,
  setUpdateGuide,
  setbehaviourGuideById,
  setBehaviourReportsbyId,
  setAddSymptomOption,
  setUpdateSymptomOption,
  setDeleteSymptomOption,
  setCreateSubOption,
  setCreateQuestion,
  setGetOneSymptomsOptions,
  setGetOneScreeningSymptoms,
  setTerms,
  setTermsUpdate,
  setAboutUs,
  setAboutUsUpdate,
} from "../../slices/dashboard";
import { Children } from "react";

function* userList(action) {
  try {
    const data = { ...action.payload };
    delete data.cb;
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.USERS_LIST}`),
      (action.params = { params: data })
    );

    if (resp.status) {
      localStorage.getItem("adminAuthToken");
      yield put(setUserList(resp.data.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* patientList(action) {
  let url = `${ApiPath.dashboardApiPath.PATIENTS_LIST}?page=${action.payload.page}&limit=${action.payload.limit}`;

  // if (Boolean(action.payload.search)) {
  //   url = `${url}&search=${action.payload.search}`;
  // }
  try {
    const resp = yield call(ApiClient.get, (action.url = url), action.payload);

    if (resp.status) {
      yield put(setUserList(resp.data));
      yield call(action.payload.cb, (action.res = resp));
      //toast.success(action.res.data.message);
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setPatientList({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* patientListByUserId(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.PATIENT_LIST_USERID}/${action.payload.userId}`)
    );

    if (resp?.status) {
      yield put(setPatientList(resp?.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setPatientList({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.msg);
  }
}

function* symptomOptions(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.SYMPTOMS_OPTIONS}`)
    );

    if (resp.status) {
      console.log(resp.data, "responsedataFroSymptoms");
      yield put(setSymptomOptions(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setSymptomOptions({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* behaviourReports(action) {
  console.log("action", action);
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.BEHAVIOUR_REPORTS}?isAdmin=${action.payload.isAdmin}&limit=${action.payload.limit}&page=${action.payload.page}`)
      // action.params = {params: data}?page=${action.payload.page}
    );

    if (resp.status) {
      yield put(setBehaviourReports(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setBehaviourReports({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* behaviourReportsAll(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.BEHAVIOUR_REPORTS}`)
      // action.params = {params: data}?page=${action.payload.page}
    );

    if (resp.status) {
      yield put(setBehaviourReportsAll(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setBehaviourReportsAll({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* screeningSymptoms(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.SCREENING_SYMPTOMS}`)
    );

    if (resp.status) {
      yield put(setScreeningSymptoms(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setScreeningSymptoms({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* getFaqList(action) {
  console.log(action, "faqlistpagination");
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.ALL_FAQ}?need_pagination=${action.payload.need_pagination}&page=${action.payload.page}&limit=${action.payload.limit}`),
      action.payload
    );

    if (resp.status) {
      yield put(setgetFaqList(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setgetFaqList({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* logoutAdmin(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = ApiPath.AuthApiPath.LOGOUT_ADMIN),
      action.payload
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
      localStorage.clear();
      // toast.success(action.res.data.message);
      yield put(setLogoutAdmin());
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(e.response.data.msg);
  }
}

function* updateUserStatus(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.dashboardApiPath.USER_UPDATE_STATUS}/${action?.payload?.userId}`),
      action.payload
    );

    if (resp.status) {
      yield put(setUpdateUserStatus(resp?.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpdateUserStatus({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.msg);
  }
}

function* updatePatientStatus(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.dashboardApiPath.PATIENT_UPDATE_STATUS}/${action.payload.patientId}`),
      action.payload
    );

    if (resp.status) {
      yield put(setUpdatePatientStatus(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpdatePatientStatus({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* privacyPolicy(action) {
  try {
    const resp = yield call(
      ApiClient.get,

      (action.url = `${ApiPath.dashboardApiPath.PRIVACY_POLICY}?slug=${action.payload.slug}`),
      action.payload
    );

    if (resp.status) {
      yield put(setPrivacyPolicy(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setPrivacyPolicy({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* privacyPolicyUpdate(action) {
  try {
    const resp = yield call(
      ApiClient.put,

      (action.url = `${ApiPath.dashboardApiPath.PRIVACY_POLICY}/${action.payload.id}`),
      action.payload
    );

    if (resp.status) {
      toast.success(resp.data.msg);
      yield put(setPrivacyPolicyUpdate(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setPrivacyPolicyUpdate({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* terms(action) {
  try {
    const resp = yield call(
      ApiClient.get,

      (action.url = `${ApiPath.dashboardApiPath.PRIVACY_POLICY}?slug=${action.payload.slug}`),
      action.payload
    );

    if (resp.status) {
      yield put(setTerms(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setTerms({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* termsUpdate(action) {
  try {
    const resp = yield call(
      ApiClient.put,

      (action.url = `${ApiPath.dashboardApiPath.PRIVACY_POLICY}/${action.payload.id}`),
      action.payload
    );

    if (resp.status) {
      toast.success(resp.data.msg);
      yield put(setTermsUpdate(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setTermsUpdate({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* aboutUs(action) {
  try {
    const resp = yield call(
      ApiClient.get,

      (action.url = `${ApiPath.dashboardApiPath.PRIVACY_POLICY}?slug=${action.payload.slug}`),
      action.payload
    );

    if (resp.status) {
      yield put(setAboutUs(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAboutUs({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* aboutUsUpdate(action) {
  try {
    const resp = yield call(
      ApiClient.put,

      (action.url = `${ApiPath.dashboardApiPath.PRIVACY_POLICY}/${action.payload.id}`),
      action.payload
    );

    if (resp.status) {
      toast.success(resp.data.msg);
      yield put(setAboutUsUpdate(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAboutUsUpdate({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}
function* imageUpload(action) {
  try {
    const resp = yield call(
      ApiClient.postFormData,
      (action.url = ApiPath.dashboardApiPath.FILE_UPLOAD),
      action.payload
    );
    // toast.success(resp.data.msg);
    yield put(setImageUpload(resp.data));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    console.log("call")
    yield put(setImageUpload({}));
  }
}

function* createGuide(action) {
  console.log("createDataApiii");

  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = ApiPath.dashboardApiPath.CREATE_GUIDE),
      action.payload
    );

    if (resp.status) {
      toast.success(resp.data.msg);
      yield put(setCreateGuide(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    console.log(e,"my error")
    yield put(setCreateGuide({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* addFaq(action) {
  try {
    const resp = yield call(
      ApiClient.post,

      (action.url = ApiPath.dashboardApiPath.CREATE_FAQ),
      action.payload
    );

    if (resp.status) {
      toast.success(resp.data.msg);
      yield put(setAddFaq(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAddFaq({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* updateFaq(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.dashboardApiPath.CREATE_FAQ}/${action.payload.id}`),
      action.payload
    );

    if (resp.status) {
      toast.success(resp?.data?.msg);
      yield put(setUpdateFaq(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpdateFaq({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* deleteFaq(action) {
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.dashboardApiPath.CREATE_FAQ}/${action.payload.id}`)
    );

    yield put(setDeleteFaq(resp.data));
    yield call(action.payload.cb, (action.res = resp));
    toast.success(action.res.data.msg);
  } catch (e) {
    yield put(setDeleteFaq({}));
  }
}

function* deleteGuide(action) {
  console.log(action, "deleteAction");
  try {
    const resp = yield call(
      ApiClient.delete,
      (action.url = `${ApiPath.dashboardApiPath.CREATE_GUIDE}/${action.payload.id}`)
    );
    console.log(resp?.data?.msg, "response for delete");
    console.log(action?.res, "nnnnnnnn");
    toast.success(resp?.data?.msg);
    yield put(setDeleteGuide(resp.data));
    yield call(action.payload.cb, (action.res = resp));
  } catch (e) {
    yield put(setDeleteGuide({}));
  }
}

function* updateGuide(action) {
  try {
    const resp = yield call(
      ApiClient.put,
      (action.url = `${ApiPath.dashboardApiPath.CREATE_GUIDE}/${action.payload.id}`),
      action.payload
    );

    if (resp.status) {
      toast.success(resp?.data?.msg);
      yield put(setUpdateGuide(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpdateGuide({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* behaviourReportsbyId(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.BEHAVIOUR_REPORTS}/${action.payload.id}`)
    );

    if (resp?.status) {
      yield put(setBehaviourReportsbyId(resp?.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setBehaviourReportsbyId({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.msg);
  }
}

function* behaviourGuideById(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.CREATE_GUIDE}/${action.payload.id}`)
    );

    if (resp?.status) {
      yield put(setbehaviourGuideById(resp?.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setbehaviourGuideById({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.msg);
  }
}

function* addSymptomOption(action) {
  try {
    const resp = yield call(
      ApiClient.post,

      (action.url = `${ApiPath.dashboardApiPath.UPDATE_SYMPTOMS_OPTIONS}`),
      action.payload
    );

    if (resp.status) {
      // toast.success(resp.data.msg);
      yield put(setAddSymptomOption(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setAddSymptomOption({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* updateSymptomOption(action) {
  try {
    const resp = yield call(
      ApiClient.post,

      (action.url = `${ApiPath.dashboardApiPath.UPDATE_SYMPTOMS_OPTIONS}`),
      action.payload
    );

    if (resp.status) {
      // toast.success(resp.data.msg);
      yield put(setUpdateSymptomOption(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setUpdateSymptomOption({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* deleteSymptomOption(action) {
  console.log(action, "deletAction");
  try {
    const resp = yield call(
      ApiClient.post,

      (action.url = `${ApiPath.dashboardApiPath.UPDATE_SYMPTOMS_OPTIONS}`),
      action.payload
    );

    if (resp.status) {
      // toast.success(resp.data.msg);
      if (resp.status === 200) {
        toast.success("Data Deleted Successfully");
      }
      yield put(setDeleteSymptomOption(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setDeleteSymptomOption({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* createSubOption(action) {
  try {
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.dashboardApiPath.UPDATE_SCREENING_SYMPTOMS}`),
      action.payload
    );

    if (resp.status) {
      // toast.success(resp.data.msg);
      yield put(setCreateSubOption(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setCreateSubOption({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* createQuestion(action) {
  try {
    const resp = yield call(
      ApiClient.post,

      (action.url = `${ApiPath.dashboardApiPath.UPDATE_SCREENING_SYMPTOMS}`),
      action.payload
    );

    if (resp.status) {
      // toast.success(resp.data.msg);
      yield put(setCreateQuestion(resp.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setCreateQuestion({}));
    yield put(onErrorStopLoad());
    toast.error(e.response.data.msg);
  }
}

function* getOneSymptomsOptions(action) {
  console.log(action, "oneSymptomAction");

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.SYMPTOMS_OPTIONS}/${action.payload.id}?key=${action.payload.key}`)
    );
    console.log(resp, "mysymptomrespdataas");
    if (resp?.status) {
      yield put(setGetOneSymptomsOptions(resp?.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetOneSymptomsOptions({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.msg);
  }
}
function* getOneScreeningSymptoms(action) {
  console.log(action,"screeningaction")
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.dashboardApiPath.SCREENING_SYMPTOMS}/${action.payload.screeningId}?key=${action.payload.key}`)
    );
    console.log(resp, "mysymptomrespdataas");
    if (resp?.status) {
      yield put(setGetOneScreeningSymptoms(resp?.data));
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }
  } catch (e) {
    yield put(setGetOneScreeningSymptoms({}));
    yield put(onErrorStopLoad());
    toast.error(e?.response?.data?.msg);
  }
}

function* webSaga() {
  yield all([takeLatest("web/userList", userList)]);
  yield all([takeLatest("web/patientList", patientList)]);
  yield all([takeLatest("web/patientListByUserId", patientListByUserId)]);
  yield all([takeLatest("web/symptomOptions", symptomOptions)]);
  yield all([takeLatest("web/behaviourReports", behaviourReports)]);
  yield all([takeLatest("web/logoutAdmin", logoutAdmin)]);
  yield all([takeLatest("web/screeningSymptoms", screeningSymptoms)]);
  yield all([takeLatest("web/updateUserStatus", updateUserStatus)]);
  yield all([takeLatest("web/updatePatientStatus", updatePatientStatus)]);
  yield all([takeLatest("web/privacyPolicy", privacyPolicy)]);
  yield all([takeLatest("web/privacyPolicyUpdate", privacyPolicyUpdate)]);
  yield all([takeLatest("web/imageUpload", imageUpload)]);
  yield all([takeLatest("web/createGuide", createGuide)]);
  yield all([takeLatest("web/behaviourReportsAll", behaviourReportsAll)]);
  yield all([takeLatest("web/getFaqList", getFaqList)]);
  yield all([takeLatest("web/addFaq", addFaq)]);
  yield all([takeLatest("web/updateFaq", updateFaq)]);
  yield all([takeLatest("web/deleteFaq", deleteFaq)]);
  yield all([takeLatest("web/deleteGuide", deleteGuide)]);
  yield all([takeLatest("web/updateGuide", updateGuide)]);
  yield all([takeLatest("web/behaviourReportsbyId", behaviourReportsbyId)]);
  yield all([takeLatest("web/behaviourGuideById", behaviourGuideById)]);
  yield all([takeLatest("web/addSymptomOption", addSymptomOption)]);
  yield all([takeLatest("web/updateSymptomOption", updateSymptomOption)]);
  yield all([takeLatest("web/deleteSymptomOption", deleteSymptomOption)]);
  yield all([takeLatest("web/createSubOption", createSubOption)]);
  yield all([takeLatest("web/createQuestion", createQuestion)]);
  yield all([takeLatest("web/getOneSymptomsOptions", getOneSymptomsOptions)]);
  yield all([
    takeLatest("web/getOneScreeningSymptoms", getOneScreeningSymptoms),
  ]);
  yield all([takeLatest("web/terms", terms)]);
  yield all([takeLatest("web/termsUpadate", termsUpdate)]);
  yield all([takeLatest("web/aboutUs", aboutUs)]);
  yield all([takeLatest("web/aboutUsUpdate", aboutUsUpdate)]);
}

export default webSaga;
