import React, { useState, useEffect } from "react";
import * as images from "../../utilities/images";

import { useDispatch } from "react-redux";
import {
  resetPassword,
  onErrorStopLoad,
  changePassword,
} from "../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";
import logo from "../Images/logo.png";
import eyefill from "../Images/eyefill.png";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const toastId = React.useRef(null);
  const user_Id = authData?.enterOtp?.user_id;
  const [errorMessages, setErrorMessages] = useState({});
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setConfirmPasswordShown] = useState(false);
  const [formData, setFormData] = useState({
    currentPassword: "",

    newPassword: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.currentPassword || !formData.newPassword) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your password");
        return false;
      }
    } else if (formData.newPassword.length < 8) {
      toastId.current = toast.error(
        "Password must be at least 8 characters long."
      );
      return;
    } else if (!/\d/.test(formData.newPassword)) {
      toastId.current = toast.error(
        "Password must contain at least one number."
      );
      return;
    } else if (!/[A-Z]/.test(formData.newPassword)) {
      toastId.current = toast.error(
        "Password must contain at least one uppercase letter."
      );
      return;
    } else if (!/[!@#$%^&*]/.test(formData.newPassword)) {
      toastId.current = toast.error(
        "Password must contain at least one special character (!@#$%^&*)"
      );
      return;
    }

    let params = {
      current_password: formData.currentPassword,
      new_password: formData.newPassword,
    };

    dispatch(
      changePassword({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/users");
          }
        },
      })
    );
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(passwordConfirmShown ? false : true);
  };
  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      {/* <div className="login-page authBg resetpwd">
        
        <div className="card card-outline card-primary cardTopOutline cardBg p-0">
          <div className="loginPage">
            <div className="card-header text-center border-0 p-0">
              <h3 className="mb-0 logintext"> Change Password</h3>
              <p className="form_subheading mt-3">
                Create your new password here.{" "}
              </p>
            </div>
            <div className="card-body login-card-body p-0">
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                

                <div className="row">
                  <div className="col-12 text-center">
                    <button
                      className="loginBtnCommon btnYellow mt-4 mw-100 loginbtn mb-0"
                      type="submit"
                    >
                      {authData.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Change Password</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      <div className="courseCard justify-content-between p-4">
        <div className="d-flex align-items-center">
          <div className="boxImg">
            <img src={images.keyIcon} alt="notification" />
          </div>
          <div className="centerText">
            <p className="heading">Change Password</p>
            {/* <p className="uploadSpan">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. has been the industry's standard dummy text
            </p> */}
          </div>
        </div>
      </div>
      <div className="container changePWform courseCard flex-column">
        <div className="card-body w-100">
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="row">
              <div className="input-container col-md-6">
                <label className="passlabel">Current Password</label>
                <div className="position-relative">
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control form-input borderinput"
                    name="currentPassword"
                    placeholder="Current Password"
                    value={formData.currentPassword}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />

                  <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      togglePasswordVisiblity(!passwordShown);
                    }}
                  >
                    <span
                      className={
                        passwordShown
                          ? "hide-icon togglePassword "
                          : "show-icon togglePassword"
                      }
                      id=""
                    ></span>
                  </span>
                </div>
                <span className="error invalid-feedback">
                  {errorMessages.password}
                </span>
              </div>

              <div className="input-container col-md-6">
                <label className="passlabel">New Password</label>
                <div className="position-relative">
                  <input
                    type={passwordConfirmShown ? "text" : "password"}
                    className="form-control form-input borderinput"
                    name="newPassword"
                    placeholder="New  Password"
                    value={formData.newPassword}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />

                  <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      toggleConfirmPasswordVisiblity(!passwordConfirmShown);
                    }}
                  >
                    <span
                      className={
                        passwordConfirmShown
                          ? "hide-icon togglePassword "
                          : "show-icon togglePassword "
                      }
                      id=""
                    ></span>
                  </span>
                </div>
                <span className="error invalid-feedback">
                  {errorMessages.confirmpassword}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-start">
                <button
                  className="loginBtnCommon btnYellow mt-4 mw-100 loginbtn mb-0"
                  type="submit"
                >
                  {authData.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  &nbsp;&nbsp;
                  <span>Change Password</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
