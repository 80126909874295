import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import CustomModal from "../Modal/CustomModal";
import * as images from "../../utilities/images";
import CreateWordOption from "../Modal/createWordOption";
import CreateWordQuestion from "../Modal/createWordQuestion";
import { useDispatch } from "react-redux";
import { getOneScreeningSymptoms } from "../../redux/slices/dashboard";
import { toast } from "react-toastify";

function WordScreening() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const dispatch = useDispatch();
  const [word, setWord] = useState();
  const [key, setKey] = useState(Math.random());
  const pathName = window.location.pathname;
  const parts = pathName.split("/"); 
  const pathkey = parts[parts.length - 1];

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handleCreateModal = (flag, questionId, question, optionId, optionText) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
      questionId: questionId,
      Question: question,
      optionId: optionId,
      optionText: optionText,
      // dataId: dataId,
    });
    setKey(Math.random());
  };

  const screeningData = state?.screening.find(
    (item) => item.key === "wordScreening"
  );
  const oneScreening = () => {

    let params = {
      screeningId: screeningData?.id,
      key: pathkey,
    };
    dispatch(
      getOneScreeningSymptoms({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setWord(res?.data?.payload);


          } else {
            // console.log("something went wrong");
          }
        },
      })
    );
  };

  useEffect(() => {
    oneScreening();
  }, [])
  return (
    <div className="content-wrapper categories">
    <div className="content-header">
      <div className="container-fluid">
    <>
      <img
        onClick={() => {
          navigate("/screening");
        }}
        src={images.arrowMain}
        alt="arrowMainimg"
        className="img-fluid cursor pb-3 me-3"
      />
       <h1 className="text-capital d-inline-block mb-3">Title: WordScreening</h1>
      {word ? (
        <div>
         
          {word.data && word.data.length > 0 ? (
            <>
              <button
                className="activeUser me-3"
                onClick={() => {
                  handleCreateModal("createQuestion");
                }}
              >
                Create Question
              </button>
              {word.data.map((item, i) => (
                <div key={i} className="mt-4">
                  <p className=" fw-bold mainOption">
                    <b className="me-3" style={{lineHeight:'56px'}}>
                      Question {i + 1}: {item.question}
                    </b>
                    <button
                      className="activeUser me-3"
                      onClick={() => {
                        handleCreateModal(
                          "createOption",
                          item.id,
                          item.question
                        );
                      }}
                    >
                      Create Option
                    </button>
                    <button
                      className="activeUser"
                      onClick={() => {
                        handleCreateModal(
                          "updateQuestion",
                          item.id,
                          item.question
                        );
                      }}
                    >
                      Update Question
                    </button>
                  </p>
                  {item.options && item.options.length > 0 && (
                    <div className="mb-4">
                      <ul className="option_">
                        {item.options.map((option, index) => (
                          <li key={index} className=" mainOption">
                            <span className="me-2">Option {index + 1} : </span>{" "}
                            <span className="me-3">{option?.text}</span>
                            <button
                              className="activeUser"
                              onClick={() => {
                                handleCreateModal(
                                  "updateOption",
                                  item.id,
                                  item.question,
                                  option.id,
                                  option.text
                                );
                              }}
                            >
                              update option
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <p>No data available</p>
          )}
        </div>
      ) : (
        <p>No screening data available</p>
      )}

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "createOption" ? "commonWidth customContent" : ""
        }
        ids={modalDetail.flag === "createOption" ? "createOption" : ""}
        child={
          modalDetail.flag === "createOption" ? (
            <CreateWordOption
              screeningData={word}
              questionId={modalDetail.questionId}
              question={modalDetail.Question}
              oneScreening={oneScreening}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "createQuestion" ? (
            <CreateWordQuestion
              screeningData={word}
              oneScreening={oneScreening}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "updateQuestion" ? (
            <CreateWordQuestion
              isEdit={true}
              questionId={modalDetail.questionId}
              question={modalDetail.Question}
              screeningData={word}
              oneScreening={oneScreening}
              close={() => handleOnCloseModal()}
            />
          ) : modalDetail.flag === "updateOption" ? (
            <CreateWordOption
              isEdit={true}
              question={modalDetail.Question}
              questionId={modalDetail.questionId}
              optionId={modalDetail.optionId}
              optionText={modalDetail.optionText}
              screeningData={word}
              oneScreening={oneScreening}
              close={() => handleOnCloseModal()}
            />
          ) : (
            //   : modalDetail.flag === "viewGuide" ? (
            //     <ViewGuide
            //       guide={modalDetail.data}
            //       close={() => handleOnCloseModal()}
            //     />
            //   ) : modalDetail.flag === "updateGuide" ? (
            //     <CreateGuides
            //       isEdit={true}
            //       getAllGuides={guides}
            //       guide={modalDetail.data}
            //       close={() => handleOnCloseModal()}
            //     />
            //   )
            ""
          )
        }
        header={
          modalDetail.flag === "createOption" ? (
            <>
              <h2 className="modal_Heading">Create Option</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "createQuestion" ? (
            <>
              <h2 className="modal_Heading">Create Question</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "updateQuestion" ? (
            <>
              <h2 className="modal_Heading"> Update Question </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>
          ) : modalDetail.flag === "updateOption" ? (
            <>
              <h2 className="modal_Heading"> Update Option </h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCross} className="ModalCance" alt="" />
              </p>
            </>

          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
    </div>
          </div>
          </div>
  );
}

export default WordScreening;
