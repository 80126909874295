const ApiPath = {
  AuthApiPath: {
    ADMIN_LOGIN: "/api/v1/users/sign-in",
    FORGOT_PASSWORD: "/api/v1/users/forgot-password",
    RESET_PASSWORD: "/api/v1/users/reset-password",
    CHANGE_PASSWORD: "/api/v1/users/change-password",
    LOGOUT_ADMIN: "/api/v1/users/logout",
    ENTER_OTP: "/api/v1/users/verify-email-otp",
    RSEND_ENTER_OTP: "/api/v1/users/resend-email-otp",
  },
  dashboardApiPath: {
    USERS_LIST: "/api/v1/users/all",
    PATIENTS_LIST: "/api/v1/adrd_patients/all",
    PATIENT_LIST_USERID: "/api/v1/adrd_patients/get-by-user",
    SYMPTOMS_OPTIONS: "/api/v1/adrd_patients/symptoms-options",
    BEHAVIOUR_REPORTS: "/api/v1/behavior_reports",
    SCREENING_SYMPTOMS: "/api/v1/adrd_patients/screening-symptoms",
    USER_UPDATE_STATUS: "/api/v1/users",
    PATIENT_UPDATE_STATUS: "/api/v1/adrd_patients",
    PRIVACY_POLICY: "/api/v1/content_management",
    FILE_UPLOAD: "/api/v1/adrd_patients/file-upload",
    CREATE_GUIDE: "/api/v1/behavior_guide",
    ALL_FAQ: "/api/v1/faqs/all",
    CREATE_FAQ: "/api/v1/faqs",
    UPDATE_SYMPTOMS_OPTIONS :"/api/v1/adrd_patients/symptoms-options/update",
    UPDATE_SCREENING_SYMPTOMS:"/api/v1/adrd_patients/screening-symptoms/update",
   
  },
};

export default ApiPath;
