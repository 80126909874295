import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createSubOption } from "../../redux/slices/dashboard";
import { toast } from "react-toastify";

function CreateSubOption(props) {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const toastId = React.useRef(null);
  const [title, setTitle] = useState("");
  const [id, setId] = useState(props?.screeningData?.id);
  const handleOptions = (e) => {
    if (!text.trim() || /^\s*$/.test(text) || text.length < 2) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Option should not be empty, consist only of whitespace, or be less than 2 characters"
        );
      }
      return;
    }
    const screeningData = props?.screeningData?.data;

    const options = screeningData.length > 0 ? screeningData[0].data : [];

    const matchedOption = options.find(
      (option) => option.id === props?.optionId
    );

    let innerOptions = [];

    if (matchedOption) {
      innerOptions = matchedOption.options;
    }
    console.log(innerOptions, "innerOptions");

    let params;

    // if (props?.isEdit) {
    //   params = {
    //     key: "recallScreening",
    //     id: id.toString(),
    //     data: [
    //       {
    //         id: "1",
    //         data: options.map((option) => ({
    //           id: option.id,
    //           title: option.title,
    //           options:
    //             option.id === props?.optionId
    //               ? [...option.options, { id: props?.innerOptionId, text: text }]
    //               : option.options,
    //         })),
    //         question: props?.question,
    //       },
    //     ],
    //   };
    if (props?.isEdit) {
      params = {
        key: "recallScreening",
        id: id.toString(),
        data: [
          {
            id: "1",
            data: options.map((option) => ({
              id: option.id,
              title: option.title,
              options:
                option.id === props?.optionId
                  ? option.options.map((innerOption) =>
                      innerOption.id === props?.innerOptionId
                        ? { id: innerOption.id, text: text }
                        : innerOption
                    )
                  : option.options,
            })),
            question: props?.question,
          },
        ],
      };

      dispatch(
        createSubOption({
          ...params,
          cb(res) {
            if (res.status === 200) {
              toast.success("Data Updated Successfully");
              props?.close();
              props?.oneScreening();
            } else {
              toast.error("Something Went Wrong");
            }
          },
        })
      );
    } else {
      params = {
        key: "recallScreening",
        id: id.toString(),
        data: [
          {
            id: "1",
            data: options.map((option) => ({
              id: option.id,
              title: option.title,
              options:
                option.id === props?.optionId
                  ? [...option.options, { id: Date.now(), text: text }]
                  : option.options,
            })),
            question: props?.question,
          },
        ],
      };

      console.log(params, "createdParams");
      dispatch(
        createSubOption({
          ...params,
          cb(res) {
            if (res.status === 200) {
              toast.success("Data Created Successfully");
              props?.close();
              props?.oneScreening();
            } else {
              toast.error("Something Went Wrong");
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    setText(props?.innerOptionText ? props?.innerOptionText : "");
  }, []);
  return (
    <div>
      <div className="mb-3">
        <label>Enter the text for options</label>
        <input
          class="form-control customFormControl"
          type="text"
          placeholder="Enter your Text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <div className="outer-btn mb-3">
        <button onClick={(e) => handleOptions(e)} className="submit-btn">
          submit
        </button>
      </div>
    </div>
  );
}

export default CreateSubOption;
