import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { adminLogout, onErrorStopLoad } from "../redux/slices/auth";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomModal from "../components/Modal/CustomModal";
// import NotificationModal from "../components/Modal/NotificationModal";
import * as images from "../utilities/images";
import { logoutAdmin } from "../redux/slices/dashboard";

const MainNav = (props) => {
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleUserProfile = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // logout handler
  const handleLogout = () => {
    Swal.fire({
      className: "swal-logout",
      title: "Are you sure?",
      text: "Are you sure to log out this account",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#4A69F5",
      confirmButtonText: "Yes, Log out!",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          token: localStorage.getItem("adminAuthtoken"),
        };

        dispatch(
          logoutAdmin({
            cb(res) {
              localStorage.clear();
              navigate("/");
            },
          })
        );
      }
    });
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);
  return (
    <>
      <nav className="main-header navbar navbar-inner navbar-expand navbar-white navbar-light adminav dashboardNav">
        <ul className="navbar-nav">
          {/* <li className="nav-item ">
            <Link
              className="nav-link dashBar_"
              data-widget="pushmenu"
              to="#"
              role="button"
            >
              <i className="fas text-white fa-bars"></i>
            </Link>
          </li> */}
        </ul>

        <ul className="navbar-nav ml-auto">
          <Link to="/change-password" className="changePassword">Change Password</Link>
          <li className="nav-item dropdown toggledash_">
            <Link className="nav-link" data-toggle="dropdown" to="#">
              <img className="userImages_" src={images.userImage_} alt="" />
            </Link>

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <div className="dropdown-divider"></div>
              <Link onClick={handleLogout} className="dropdown-item" to="#">
                <i className="fas fa-sign-out-alt mr-2 droptext"></i> Logout
              </Link>
            </div>
          </li>
          {/* <li className="nav-item">
            <Link
              className="nav-link"
              data-widget="fullscreen"
              to="#"
              role="button"
            >
              <i className="fas text-white fa-expand-arrows-alt"></i>
            </Link>
          </li> */}
        </ul>
      </nav>
      {/* <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "notificationModal"
            ? "commonWidth customContent"
            : ""
        }
        ids={modalDetail.flag === "notificationModal" ? "notificationBtn" : ""}
        child={
          modalDetail.flag === "notificationModal" ? (
            <NotificationModal close={() => handleOnCloseModal()} />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "notificationModal" ? (
            <>
              <h2 className="modal_Heading">Notifications</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel">
                <img src={images.modalCancel} className="ModalCancel" alt="" />
              </p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      /> */}
    </>
  );
};

export default MainNav;
