import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  buttonLoading: false,
};

export const webSlice = createSlice({
  name: "web",
  initialState,
  reducers: {
    checkExistCategory: (state) => {
      state.loading = true;
    },
    setCheckExistCategory: (state) => {
      state.loading = false;
    },
    getDashboardUsers: (state) => {
      state.loading = true;
    },
    setDashboardUsers: (state) => {
      state.loading = false;
    },
    userList: (state) => {
      state.loading = true;
    },
    setUserList: (state) => {
      state.loading = false;
    },
    patientList: (state) => {
      state.loading = true;
    },
    setPatientList: (state) => {
      state.loading = false;
    },
    patientListByUserId: (state) => {
      state.loading = true;
    },
    setPatientListByUserId: (state) => {
      state.loading = true;
    },

    userDetail: (state) => {
      state.loading = true;
    },
    setuserDetail: (state) => {
      state.loading = false;
    },

    symptomOptions: (state) => {
      state.loading = true;
    },
    setSymptomOptions: (state) => {
      console.log("loading is falseeeee");
      state.loading = false;
      console.log(state.loading, "stateloadingindsaga");
    },

    behaviourReports: (state) => {
      state.loading = true;
    },
    setBehaviourReports: (state) => {
      state.loading = false;
    },
    behaviourReportsAll: (state) => {
      state.loading = true;
    },
    setBehaviourReportsAll: (state) => {
      state.loading = false;
    },
    screeningSymptoms: (state) => {
      state.loading = true;
    },
    setScreeningSymptoms: (state) => {
      state.loading = false;
    },

    updateUserStatus: (state) => {
      state.loading = true;
    },
    setUpdateUserStatus: (state) => {
      state.loading = false;
    },
    updatePatientStatus: (state) => {
      state.loading = true;
    },
    setUpdatePatientStatus: (state) => {
      state.loading = false;
    },
    privacyPolicy: (state) => {
      state.loading = true;
      state.buttonLoading = true;
    },
    setPrivacyPolicy: (state) => {
      state.loading = false;
      state.buttonLoading = false;
    },
    privacyPolicyUpdate: (state) => {
      state.loading = true;
      state.buttonLoading = true;
    },
    setPrivacyPolicyUpdate: (state) => {
      state.loading = false;
      state.buttonLoading = false;
    },
    terms: (state) => {
      state.loading = true;
      state.buttonLoading = true;
    },
    setTerms: (state) => {
      state.loading = false;
      state.buttonLoading = false;
    },
    aboutUs: (state) => {
      state.loading = true;
      state.buttonLoading = true;
    },
    setAboutUs: (state) => {
      state.loading = false;
      state.buttonLoading = false;
    },
    termsUpdate: (state) => {
      state.loading = true;
      state.buttonLoading = true;
    },
    setTermsUpdate: (state) => {
      state.loading = false;
      state.buttonLoading = false;
    },
    aboutUsUpdate: (state) => {
      state.loading = true;
      state.buttonLoading = true;
    },
    setAboutUsUpdate: (state) => {
      state.loading = false;
      state.buttonLoading = false;
    },

    imageUpload: (state) => {
      state.loading = true;
    },
    setImageUpload: (state) => {
      state.loading = false;
    },
    createGuide: (state) => {
      console.log("done1");
      state.loading = true;
    },
    setCreateGuide: (state) => {
      state.loading = false;
    },
    getFaqList: (state) => {
      state.loading = true;
    },
    setgetFaqList: (state) => {
      state.loading = false;
    },
    addFaq: (state) => {
      state.loading = true;
    },
    setAddFaq: (state) => {
      state.loading = false;
    },

    updateFaq: (state) => {
      state.loading = true;
    },
    setUpdateFaq: (state) => {
      state.loading = false;
    },
    deleteFaq: (state) => {
      state.loading = true;
    },
    setDeleteFaq: (state) => {
      state.loading = false;
    },
    deleteGuide: (state) => {
      state.loading = true;
    },
    setDeleteGuide: (state) => {
      state.loading = false;
    },

    updateGuide: (state) => {
      state.loading = true;
    },
    setUpdateGuide: (state) => {
      state.loading = false;
    },
    behaviourGuideById: (state) => {
      state.loading = true;
    },
    setbehaviourGuideById: (state) => {
      state.loading = false;
    },

    addSymptomOption: (state) => {
      state.loading = false;
    },
    setAddSymptomOption: (state) => {
      state.loading = true;
    },
    updateSymptomOption: (state) => {
      state.loading = true;
    },
    setUpdateSymptomOption: (state) => {
      state.loading = false;
    },
    deleteSymptomOption: (state) => {
      state.loading = true;
    },
    setDeleteSymptomOption: (state) => {
      state.loading = false;
    },
    createSubOption: (state) => {
      state.loading = true;
    },
    setCreateSubOption: (state) => {
      state.loading = false;
    },
    createQuestion: (state) => {
      state.loading = true;
    },
    setCreateQuestion: (state) => {
      state.loading = false;
    },
    getOneSymptomsOptions: (state) => {
      state.loading = true;
    },
    setGetOneSymptomsOptions: (state) => {
      state.loading = false;
    },
    getOneScreeningSymptoms: (state) => {
      state.loading = true;
    },
    setGetOneScreeningSymptoms: (state) => {
      state.loading = false;
    },

    behaviourReportsbyId: (state) => {
      state.loading = true;
    },
    setBehaviourReportsbyId: (state) => {
      state.loading = false;
    },
    deleteUserByadmin: (state) => {
      state.loading = true;
    },
    setDeleteUserByadmin: (state) => {
      state.loading = false;
    },
    logoutAdmin: (state) => {
      state.loading = true;
    },
    setLogoutAdmin: (state) => {
      state.loading = false;
    },
    updateStatus: (state) => {
      state.loading = true;
    },
    setUpdateStatus: (state) => {
      state.loading = false;
    },  
   
    onErrorStopLoad: (state) => {
      state.loading = false;
      state.buttonLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getDashboardUsers,
  setDashboardUsers,
  userList,
  setUserList,
  patientList,
  setPatientList,
  patientListByUserId,
  setPatientListByUserId,
  userDetail,
  setuserDetail,
  symptomOptions,
  setSymptomOptions,
  behaviourReports,
  setBehaviourReports,
  screeningSymptoms,
  setScreeningSymptoms,
  updateUserStatus,
  setUpdateUserStatus,
  updatePatientStatus,
  setUpdatePatientStatus,
  privacyPolicy,
  setPrivacyPolicy,
  terms,
  setTerms,
  aboutUs,
  setAboutUs,

  privacyPolicyUpdate,
  setPrivacyPolicyUpdate,
  termsUpdate,
  setTermsUpdate,
  aboutUsUpdate,
  setAboutUsUpdate,

  imageUpload,
  setImageUpload,
  createGuide,
  setCreateGuide,
  behaviourReportsAll,
  setBehaviourReportsAll,
  getFaqList,
  setgetFaqList,
  addFaq,
  setAddFaq,
  updateFaq,
  setUpdateFaq,
  deleteFaq,
  setDeleteFaq,
  deleteGuide,
  setDeleteGuide,
  updateGuide,
  setUpdateGuide,
  behaviourReportsbyId,
  setBehaviourReportsbyId,
  getOneSymptomsOptions,
  setGetOneSymptomsOptions,
  getOneScreeningSymptoms,
  setGetOneScreeningSymptoms,

  behaviourGuideById,
  setbehaviourGuideById,
  addSymptomOption,
  setAddSymptomOption,
  updateSymptomOption,
  setUpdateSymptomOption,
  deleteSymptomOption,
  setDeleteSymptomOption,
  createSubOption,
  setCreateSubOption,
  createQuestion,
  setCreateQuestion,
  deleteUserByadmin,
  setDeleteUserByadmin,
  logoutAdmin,
  setLogoutAdmin,
  updateStatus,
  setUpdateStatus,
  usersList,
  setusersList,
} = webSlice.actions;

export default webSlice.reducer;
