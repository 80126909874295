import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetPassword, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";
import logo from "../../../src/public/images/sideLogo.png";
import eyefill from "../Images/eyefill.png";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const toastId = React.useRef(null);
  const user_Id = authData?.enterOtp?.user_id;
  const [errorMessages, setErrorMessages] = useState({});
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmShown, setConfirmPasswordShown] = useState(false);
  const [formData, setFormData] = useState({
    phone: "",

    password: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.password) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your password");
        return false;
      }
    } else if (formData.password.length < 8) {
      toastId.current = toast.error(
        "Password must be at least 8 characters long."
      );
      return;
    } else if (!/\d/.test(formData.password)) {
      toastId.current = toast.error(
        "Password must contain at least one number."
      );
      return;
    } else if (!/[A-Z]/.test(formData.password)) {
      toastId.current = toast.error(
        "Password must contain at least one uppercase letter."
      );
      return;
    } else if (!/[!@#$%^&*]/.test(formData.password)) {
      toastId.current = toast.error(
        "Password must contain at least one special character (!@#$%^&*)"
      );
      return;
    } 

    let params = {
      email:formData.email,
      password: formData.password,
    };
    dispatch(
      resetPassword({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/");
          }
        },
      })
    );
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(passwordConfirmShown ? false : true);
  };
  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="login-page authBg resetpwd">
        <div className="brandlogo">
        <img src={logo} alt="brandlogo" className="logoMain"/>
        </div>
        <div className="card card-outline card-primary cardTopOutline cardBg p-0">
          <div className="loginPage">
            <div className="card-header text-center border-0 p-0">
              <h3 className="mb-0 logintext">Reset Password</h3>
              <p className="form_subheading mt-3">
                Create your new password here.{" "}
              </p>
            </div>
            <div className="card-body login-card-body p-0">
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="input-container mb-4">
                  <input
                    type="text"
                    className="form-control form-input borderinput"
                    name="email"
                    placeholder="Enter the Email"
                    value={formData.email}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  {/* <label for="username" className="form-label d-block inputBox ">
                    Email{" "}
                  </label> */}
                </div>

                <div className="input-container ">
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control form-input borderinput"
                    name="password"
                    placeholder="Enter New Password"
                    value={formData.password}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  {/* <label for="username" className="form-label d-block inputBox">
                      Password{" "}
                    </label> */}

                  <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      togglePasswordVisiblity(!passwordShown);
                    }}
                  >
                    <span
                      className={
                        passwordShown
                          ? "hide-icon togglePassword "
                          : "show-icon togglePassword"
                      }
                      id=""
                    ></span>
                  </span>
                  <span className="error invalid-feedback">
                    {errorMessages.password}
                  </span>
                </div>

                {/* <div className="input-container mt-3">
                  <input
                    type={passwordConfirmShown ? "text" : "password"}
                    className="form-control form-input borderinput"
                    name="confirmpassword"
                    placeholder="Enter Confirm Password"
                    value={formData.confirmpassword}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  {/* <label for="username" className="form-label d-block inputBox">
                      Confirm Password
                    </label> */}

                  {/* <span
                    className="toggle_password_ info_icon"
                    onClick={() => {
                      toggleConfirmPasswordVisiblity(!passwordConfirmShown);
                    }}
                  >
                    <span
                      className={
                        passwordConfirmShown
                          ? "hide-icon togglePassword "
                          : "show-icon togglePassword "
                      }
                      id=""
                    ></span>
                  </span>
                  <span className="error invalid-feedback">
                    {errorMessages.confirmpassword}
                  </span>
                </div> */} 

                <div className="row">
                  <div className="col-12 text-center">
                    <button
                      className="loginBtnCommon btnYellow mt-4 mw-100 loginbtn mb-0"
                      type="submit"
                    >
                      {authData.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp;
                      <span>Reset</span>
                    </button>
                    <p className="mt-4">Go Back To <Link to="/" className="color-login"> Login</Link></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
