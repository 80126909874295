import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { adminLogin, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";
import { toast } from "react-toastify";
import logo from "../../../src/public/images/sideLogo.png";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const [errorMessages, setErrorMessages] = useState({});
  const [passwordShown, setPasswordShown] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    // phone:""
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  // validation of input fields
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData.email) {
      formIsValid = false;
      // errors = { ...errors, email: "Please enter your email." };
      toast.error("Please enter your email address");
    } else if (typeof formData.email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(formData.email.trim())) {
        formIsValid = false;
        toast.error("Please enter valid email");
      }
    }

    if (!formData.password) {
      formIsValid = false;
      toast.error("Please enter your password.");
    } else if (formData.password.length < 8) {
      formIsValid = false;
      toast.error("Password must be at least 8 characters long.");
    } else if (!/\d/.test(formData.password)) {
      formIsValid = false;
      toast.error("Password must contain at least one number.");
    } else if (!/[A-Z]/.test(formData.password)) {
      formIsValid = false;
      toast.error("Password must contain at least one uppercase letter.");
    } else if (!/[!@#$%^&*]/.test(formData.password)) {
      formIsValid = false;
      toast.error(
        "Password must contain at least one special character (!@#$%^&*)."
      );
    }

    setErrorMessages(errors);
    return formIsValid;
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let params = {
        email: formData.email.trim(),
        password: formData.password,
        // full_phone_no:formData.phone|| undefined
      };
      console.log("jj");

      dispatch(
        adminLogin({
          ...params,
          cb(res) {
            console.log(res, "llllllllllres");
            if (res.status === 200) {
              navigate("/users");
            } else {
              console.log("hello");
            }
          },
        })
      );
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="login-page authBg adminlgn">
        <div className="brandlogo">
          <img src={logo} alt="brandlogo" className="logoMain" />
        </div>
        <div className="card card-outline card-primary cardTopOutline cardBg">
          <div className="card-header text-center border-0">
            <h3 className="mb-0 logintext">Login</h3>
            <p className="form_subheading mt-3">
              Please enter your email address & password for continue.
            </p>
          </div>
          <div className="card-body login-card-body">
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="input-container  ">
                <input
                  type="text"
                  className="form-control form-input borderinput"
                  name="email"
                  placeholder="Enter Email Address"
                  value={formData.email}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />

                <span className="error invalid-feedback">
                  {errorMessages.email}
                </span>
              </div>
              <div className="input-container mt-3">
                <input
                  type={passwordShown ? "text" : "password"}
                  className="form-control form-input borderinput"
                  name="password"
                  placeholder="Enter Your Password"
                  value={formData.password}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />

                <span
                  className="toggle_password_ info_icon"
                  onClick={() => {
                    togglePasswordVisiblity(!passwordShown);
                  }}
                >
                  <span
                    className={
                      passwordShown
                        ? "hide-icon togglePassword"
                        : "show-icon togglePassword"
                    }
                    id=""
                  ></span>
                </span>

                <span className="error invalid-feedback">
                  {errorMessages.password}
                </span>
              </div>

              <div className="row">
                <div className="col-12 text-end mt-2">
                  <Link
                    to="/forgot-password"
                    className="
                         forgotheading"
                  >
                    Forgot Password?
                  </Link>
                </div>

                <div className="col-12 text-center">
                  <button
                    className="loginBtnCommon btnYellow mt-5 mw-100 loginbtn"
                    type="submit"
                  >
                    {authData.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
