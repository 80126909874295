import React from 'react'

function FaqViewModal({faq}) {
    console.log(faq,"faqData")
  return (
    <>
    <p className='faqData'><span className='faqSpan'>Question:</span> {faq.question}?</p>
    <p className='faqData'><span className='faqSpan'>Answer:</span> {faq.answer}.</p>
    </>
  )
}

export default FaqViewModal