import React, { useEffect, useState } from "react";
import * as images from "../../utilities/images";

import { useLocation, useParams, useNavigate } from "react-router-dom";

function ScreeningDetails() {
  const { key } = useParams();
  const { state } = useLocation();
  const screeningData = state?.screening.find((item) => item.key === key);
  console.log(screeningData,"screeningggggggggg")
  const navigate = useNavigate();
  const [Key, setKey] = useState(Math.random());

  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  const handleUserProfile = (flag) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
  };
 

  return (
    <>
      <div className="userDeatilHead">
        <img
          onClick={() => {
            navigate("/screening");
          }}
          src={images.arrowMain}
          alt="arrowMainimg"
          className="img-fluid"
        />
        <h1 className="mainHead32">Screening Symptoms Details</h1>
      </div>
      {screeningData && screeningData.key === "recallScreening" ? (
        <div>
          <div className="categories_btn ms-auto">
            <button
              className="loginBtnCommon btnYellow add_btn"
              onClick={() => {
                // handleUserProfile("createScreening");
              }}
            >
              Create New
            </button>
          </div>

          {/* <h1 className="text-capital my-3">Title: {screeningData.key}</h1> */}
          {screeningData.data && screeningData.data.length > 0 ? (
            <>
              {screeningData.data.map((item, i) => (
                <div key={i}>
                  <p className="">Description: {item.question}</p>
                  {item.data && item.data.length > 0 && (
                    <div>
                      {/* <p className="">Options:</p> */}
                      {item.data.map((option, index) => (
                        <div key={index}>
                          <p className="fw-bold mainOption">
                            {" "}
                            {index + 1}) {option.title}
                          </p>
                          {option.options && option.options.length > 0 && (
                            <div className="mb-4">
                              <ul className="option_">
                                {option.options.map(
                                  (innerOption, innerIndex) => (
                                    <li key={innerIndex} className="">
                                      {" "}
                                      <span className="me-2">
                                        Option {innerIndex + 1} :{" "}
                                      </span>{" "}
                                      {innerOption.text}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <p>No data available</p>
          )}
        </div>
      ) : (
        <div>
          {screeningData ? (
            <div>
              <h1 className="text-capital">Title: {screeningData.key}</h1>
              {screeningData.data && screeningData.data.length > 0 ? (
                <>
                  {screeningData.data.map((item, i) => (
                    <div key={i} className="mt-4">
                      <p className=" fw-bold mainOption">
                        <b>
                          Question {i + 1}: {item.question}
                        </b>
                      </p>
                      {item.options && item.options.length > 0 && (
                        <div className="mb-4">
                          <ul className="option_">
                            {item.options.map((option, index) => (
                              <li key={index} className=" mainOption">
                                <span className="me-2">
                                  Option {index + 1} :{" "}
                                </span>{" "}
                                {option?.text}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <p>No data available</p>
              )}
            </div>
          ) : (
            <p>No screening data available</p>
          )}
        </div>
      )}
    </>
  );
}

export default ScreeningDetails;
