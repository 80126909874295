import moment from "moment";

import React, { useEffect, useState } from "react";
import * as images from "../../utilities/images";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  patientList,
  setPatientList,
  updatePatientStatus,
  userList,
} from "../../redux/slices/dashboard";
import { useNavigate } from "react-router-dom";

const Articles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [patient, setPatient] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [active, setActive] = useState("");
  const [status, setStatus] = useState("");

  const getallUserList = (page = 1, limit = 10) => {
    let params = {
      page: page,
      limit: limit,
    };

    dispatch(
      patientList({
        ...params,
        cb(res) {
          console.log(res, "patient Response");
          if (res?.data) {
            setPatient(res?.data?.payload?.data);
            console.log(patient, "jjjjddj");
          } else {
            toast.error("something went wrong");
          }
        },
      })
    );
  };

  // const handleChangeStatus = (id, status) => {
  //   let params = {
  //     patientId: id,

  //     status: status,
  //   };
  //   console.log(params, "parsmshhh");
  //   dispatch(
  //     updatePatientStatus({
  //       ...params,
  //       cb(res) {
  //         console.log(res.data.payload, "status");
  //         if (res?.data) {
  //           setStatus(res?.data?.payload[0]);
  //         } else {
  //           toast.error("something went wrong");
  //         }
  //       },
  //     })
  //   );
  // };
  const handleChangeStatus = (id, currentStatus) => {
    const newStatus = currentStatus === 0 ? 1 : 0;

    let params = {
      patientId: id,
      status: newStatus,
    };

    dispatch(
      updatePatientStatus({
        ...params,
        cb(res) {
          console.log(res.data.payload, "status");
          if (res?.data) {
            // Update userLists with the new status
            setPatient((prevPatientLists) => {
              return prevPatientLists.map((patient) => {
                if (patient.id === id) {
                  return { ...patient, status: newStatus };
                }
                return patient;
              });
            });

            toast.success("Patient status updated successfully");
          } else {
            toast.error("Something went wrong");
          }
        },
      })
    );
  };

  // const handleFilterData = (status) => {
  //   getallUserList(1, 10, searchValue, status);
  //   setStatus(status);
  //   setActive(status);
  // };
  const handleGoToDetailPage = (data) => {
    navigate(`/userDetail/${data?._id}`);
  };
  useEffect(() => {
    getallUserList();
  }, []);

  return (
    <>
      <div className="content-wrapper ">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="mainHead32">Patients</h1>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="searchSection">
          <input
            class="form-control searchInput"
            type="text"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              getallUserList(1, 10, e.target.value, status);
            }}
          />
          <img
            src={images.searchImg}
            alt="searchImg"
            className="img-fluid searchBarIcon"
          />
        </div> */}
        <div className="recentUserSec">
          {/* <div className="userListStatus">
            <h4
              className={
                active === "" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => handleFilterData("")}
            >
              All
            </h4>
            <h4
              className={
                active === "Active" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => handleFilterData("Active")}
            >
              Active
            </h4>
            <h4
              className={
                active === "Inactive" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => handleFilterData("Inactive")}
            >
              Deactive
            </h4>
            <h4
              className={
                active === "Blocked" ? "statusHeading active" : "statusHeading"
              }
              onClick={() => handleFilterData("Blocked")}
            >
              Block
            </h4>
          </div> */}
          <div className="table-responsive recentTable userRecentTable">
            <table class="table table-dark m-0">
              <thead>
                <tr className="recentRow">
                  <th scope="col" className="recentHead">
                    SR. NO.
                  </th>
                  <th scope="col" className="recentHead">
                    First Name
                  </th>
                  <th scope="col" className="recentHead">
                    Last Name
                  </th>
                  <th scope="col" className="recentHead">
                    Marital Status
                  </th>
                  <th>Status</th>
                  <th scope="col" className="recentHead text-center">
                    Birth Date
                  </th>
                  {/* <th scope="col" className="recentHead text-center">
                 Patient Steps
                  </th> */}
                </tr>
              </thead>

              {patient?.length > 0 ? (
                patient?.map((data, idx) => {
                  return (
                    <tbody>
                      <tr>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">{idx + 1}</h4>
                        </td>
                        <td className="recentSubHead">
                          <div className="nameProfile">
                            {/* <img
                              src={images.profileImg}
                              className="img-fluid profileImg_"
                              alt="profileImg"
                            /> */}
                            <h4 className="tableSubText">{data?.first_name}</h4>
                          </div>
                        </td>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">{data?.last_name}</h4>
                        </td>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">
                            {/* {data?.userInfo?.dialCode} {data?.userInfo?.phoneNo}
                             */}
                            {/* {data?.payload?.data}
                             */}
                            {data?.marital_status}
                          </h4>
                        </td>
                        <td className="recentSubHead">
                          <h4 className="tableSubText">
                            {/* {data?.userInfo?.dialCode} {data?.userInfo?.phoneNo}
                             */}
                            {/* {data?.payload?.data}
                             */}
                            <button>
                              <button
                                onClick={() =>
                                  handleChangeStatus(data.id, data.status)
                                }
                              >
                                {data.status === 0 ? "Activate" : "Deactivate"}{" "}
                                Patient
                              </button>
                            </button>
                          </h4>
                        </td>
                        <td className="recentSubHead text-center">
                          {moment
                            ?.utc(data?.birth_date)
                            ?.format("MMM DD, YYYY")}

                          {/* {data?.birth_date} */}
                        </td>
                        {/* <td className="recentSubHead text-center">
                         
                         {data?.patient_steps}
                       </td> */}
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <h3 className="text-center">No Users Found!</h3>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Articles;
