import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createSubOption } from "../../redux/slices/dashboard";
import { classNames } from "@react-pdf-viewer/core";
import { toast } from "react-toastify";

function CreateOrientationOption(props) {
  console.log(props, "newprops");
  const toastId = React.useRef(null);
  const [text, setText] = useState("");
  const dispatch = useDispatch();

  const handleOptions = (e) => {
    if (!text.trim() || /^\s*$/.test(text) || text.length < 2) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Option should not be empty, consist only of whitespace, or be less than 2 characters");
      }
      return; 
    }
    // Retrieve all questions data
    const allQuestionsData = props?.screeningData?.data || [];

    // Find the specific question
    const specificQuestionId = props?.questionId;
    const specificQuestion = allQuestionsData.find(
      (question) => question.id === specificQuestionId
    );

    // Extract specific qestion options
    const specificQuestionOptions = specificQuestion
      ? specificQuestion.options
      : [];
    console.log(specificQuestionOptions, "specd");
    if (props?.isEdit) {
      const updatedOptionId = props?.optionId;
      const optionIndex = specificQuestionOptions.findIndex(
        (option) => option.id === updatedOptionId
      );

      if (optionIndex !== -1) {
        const updatedOptions = specificQuestionOptions.map((option, index) => {
          if (index === optionIndex) {
            return {
              ...option,
              text: text,
            };
          }
          return option;
        });

        // Update the specific question within the allQuestionsData array
        const updatedAllQuestionsData = allQuestionsData.map((question) => {
          if (question.id === specificQuestionId) {
            return {
              ...question,
              options: updatedOptions,
            };
          }
          return question;
        });

        let params = {
          key: "orientationScreening",
          id: props?.screeningData?.id.toString(),
          data: updatedAllQuestionsData,
        };
        console.log(params, "updatedoptionsParams");

        dispatch(
          createSubOption({
            ...params,
            cb(res) {
              if (res.status === 200) {
                toast.success("Data Created Successfully");
                props?.close();
                props?.oneScreening();
              } else {
                toast.error("Something Went Wrong");
              }
            },
          })
        );
      }
    } else {
      const newOption = {
        id: Date.now().toString(),
        text: text,
      };

      const updatedOptions = [...specificQuestionOptions, newOption];

      const updatedQuestionsData = allQuestionsData.map((question) => {
        if (question.id === specificQuestionId) {
          return {
            ...question,
            options: updatedOptions,
          };
        }
        return question;
      });

      let params = {
        key: "orientationScreening",
        id: props?.screeningData?.id.toString(),
        data: updatedQuestionsData,
      };

      console.log(params, "orientationParamsData");

      dispatch(
        createSubOption({
          ...params,
          cb(res) {
            if (res.status === 200) {
              props?.close();
              props?.oneScreening();
            } else {
              console.log("error");
            }
          },
        })
      );
    }
  };
  useEffect(() => {
    setText(props?.optionText ? props?.optionText : "");
  }, []);

  return (
    <>
      <div className="mb-3">
        <label>Enter the text for options</label>
        <input
          class="form-control customFormControl"
          type="text"
          placeholder="Enter your Text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <div className="outer-btn mb-3">
        <button onClick={(e) => handleOptions(e)} className="submit-btn">
          submit
        </button>
      </div>
    </>
  );
}

export default CreateOrientationOption;
