import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { forgotPassword, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthSelector } from "../../redux/selector/auth";
import logo from "../../../src/public/images/sideLogo.png";


const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const [formData, setFormData] = useState({
    // phone: "",
    email: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.email) {
      toast.error("Please enter the email");
      return;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    let params = {
      email: formData.email,
    };
    dispatch(
      forgotPassword({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/enter-otp");
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  return (
    <>
      <div className="login-page authBg adminpwd">
        <div className="brandlogo">
          <img src={logo} alt="brandlogo" className="logoMain"/>
        </div>
        <div className="card card-outline card-primary cardTopOutline cardBg">
          <div className="loginPage">
            <div className="card-header button-es text-center border-0">
              <h3 className="mb-0 logintext">Forgot Password</h3>
              <p className="form_subheading mt-3">
                Enter registered email address to reset your password.
              </p>
            </div>
            <div className="card-body login-card-body">
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control form-input borderinput"
                    name="email"
                    placeholder="Enter the Email"
                    value={formData.email}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>

                <div className="row">
                  <div className="col-12 text-center">
                    <button
                      className="loginBtnCommon btnYellow  mw-100  loginbtn"
                      type="submit"
                    >
                      {authData.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      &nbsp;&nbsp; Request OTP
                    </button>
                   <p className="mt-4 ">Go Back To <Link to="/" className="color-login"> Login</Link></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
